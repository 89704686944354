import "vant/es/progress/style";
import _Progress from "vant/es/progress";
import "E:\\work\\h5\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "E:\\work\\h5\\node_modules\\core-js\\modules\\es.promise.js";
import "E:\\work\\h5\\node_modules\\core-js\\modules\\es.object.assign.js";
import "E:\\work\\h5\\node_modules\\core-js\\modules\\es.promise.finally.js";
// 兼容 IE
import utis from 'liudonghan';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import ElementUI from 'element-ui'
import preventReClick from '../static/preventReClick'; //防止重复点击
// import pinyin from 'js-pinyin'
Vue.use(preventReClick);

// 高德
utis.ConstantManagerUtils.config.mapKey = 'c2868746f6d0d525fc35b1f377e683c2';
utis.ConstantManagerUtils.config.mapSecurityCode = '6a5a69a56b383204455176e1c99c1f75';
utis.ConstantManagerUtils.config.logLevel = 1; // 日志级别
if (process.env.NODE_ENV == 'development') {
  utis.ConstantManagerUtils.config.timSdkId = '1400664826'; //正式1400581425 测试 1400664826
} else {
  utis.ConstantManagerUtils.config.timSdkId = '1400581425'; //正式1400581425 测试 1400664826
}
// utis.ConstantManagerUtils.config.timSdkId = '1400664826' //正式1400581425 测试 1400664826

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// library.add(faStar, faStarHalfAlt)

// Vue.component('font-awesome-icon', FontAwesomeIcon)

// 设置 js中可以访问 $cdn
import { $cdn } from '@/config';
Vue.prototype.$cdn = $cdn;
// 全局引入按需引入UI库 vant
import '@/plugins/vant';
import '@/plugins/elementui';
// 引入全局样式
import '@/assets/css/index.scss';
// 移动端适配
import 'lib-flexible/flexible.js';

// import waterfall from 'vue-waterfall2'

// import TIM from 'tim-wx-sdk'; // 微信小程序环境请取消本行注释，并注释掉 import TIM from 'tim-js-sdk';
// filters
// import '@/utils/ImagePreviewItem.js'
import { fromShare, BindUser } from './utils/PublicMethod.js';
import './writeoff/permission';

// import AmapVue from '@amap/amap-vue';
// AmapVue.config.version = '1.4';
// AmapVue.config.key = '43d50f4103b4da16fbb3edd0eb6b8413';
// Vue.use(AmapVue);

require('video.js/dist/video-js.css');
Vue.config.productionTip = false;
// Vue.use(ElementUI)
// Vue.use(waterfall)

import Directives from './directives';
Vue.use(Directives);

// import VueAMap from 'vue-amap';

// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: '7a7026a834fed9e2a992b9f76f87591b',
//   plugin: ['AMap.Marker', 'AMap.Polyline','AMap.Drivin'],
//   v: '2.0',
// });

// import FastClick from 'fastclick'
var FastClick = require('@/assets/lib/fastclick.js'); //-----解决fastclick和vue-video-player冲突问题，并且需要更改fastclick里面的源码
FastClick.attach(document.body); // 移动端使用 fastclick
if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function () {
    FastClick.attach(document.body);
  }, false);
}
import VueTouch from 'vue-touch';
Vue.use(VueTouch, {
  name: 'v-touch'
});
Vue.use(_Progress);
import audio from '@/views/personalHomepage/component/audio.js';
Vue.use(audio);
import { gainSecret } from '@/api/IM/app.js';
import { setSecret } from '@/utils/cookies';
router.beforeEach(function (to, from, next) {
  gainSecret().then(function (d) {
    if (d.code == 200) {
      setSecret(d.result);
    }
  }).catch(function (e) {});
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (from.meta.keepAlive) {
    var $wrapper = document.querySelector('.row-order'); // 列表的外层容器 注意找到滚动的盒子
    var scrollTop = $wrapper ? $wrapper.scrollTop : 0;
    from.meta.scrollTop = scrollTop;
  }
  next();
});
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  mounted: function mounted() {
    //记录分享次数
    fromShare();
    // BindUser();// 绑定上下级
    //wxAuthorize();
  }
}).$mount('#app');