import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { getLoginStatus, clearLoginStatus, getbankStatus, getTargertid } from '@/utils/cookies';
import { fromShare } from '@/utils/PublicMethod.js';
// 根据环境不同引入不同api地址
import { baseApi, userApi, payApi, settleinApi } from '@/config';
// create an axios instance

var service = axios.create({
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // },
  // baseURL: baseApi, // url = base api url + request url
  withCredentials: false,
  // send cookies when cross-domain requests
  timeout: 20000 // request timeout
});
function getQueryString(name) {
  // 未传参，返回空
  if (!name) return null;
  // 查询参数：先通过search取值，如果取不到就通过hash来取
  var after = window.location.search;
  after = after.substr(1) || window.location.hash.split('?')[1];
  // 地址栏URL没有查询参数，返回空
  if (!after) return null;
  // 如果查询参数中没有"name"，返回空
  if (after.indexOf(name) === -1) return null;
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  // 当地址栏参数存在中文时，需要解码，不然会乱码
  var r = decodeURIComponent(after).match(reg);
  // 如果url中"name"没有值，返回空
  if (!r) return null;
  return r[2];
}
;
// request拦截器 request interceptor
service.interceptors.request.use(function (config) {
  // getQueryString('token');
  // 不传递默认开启loading
  if (!config.hideloading) {
    // loading
    // Toast.loading({
    //   message: '加载中..',
    //   forbidClick: true,
    //   duration:0
    // })
  }
  var token = getLoginStatus();
  if (store.getters.token) {
    config.headers['X-Token'] = '';
  }

  // 直播间关注和取消关注
  if (sessionStorage.getItem('live_token') && config.url.indexOf('setFollow') > -1) {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('live_token');
  }
  // 橱窗的一些操作
  else if (config.url.indexOf('getMyRoomIndex') > -1 || config.url.indexOf('zb-goods-list') > -1 || config.url.indexOf('deleteGoods') > -1 || config.url.indexOf('setOtherChoice') > -1) {
    var to = getQueryString('token') ? getQueryString('token') : getLoginStatus();
    config.headers['Authorization'] = 'Bearer ' + to;
  } else if (sessionStorage.getItem('heheToken') && config.url.indexOf('api/Item') > -1) {
    var heheToken = sessionStorage.getItem('heheToken');
    // console.log(heheToken);
    config.headers['Authorization'] = 'Bearer ' + heheToken;
  }
  // 其他普通的接口 || 获取地址栏中的（直播间的操作（商详、规格等等接口））
  else if (token !== '' && token != undefined || getQueryString('token')) {
    var _to = getLoginStatus() ? getLoginStatus() : getQueryString('token');
    config.headers['Authorization'] = 'Bearer ' + _to;
  }

  // else {
  //   config.headers['Authorization'] = 'Bearer ' + getbankStatus()
  // }

  if (config.method === "get") {
    // // console.log(stringify(config.data),'config.dataconfig.data')
    config.url = config.url + "?" + stringify(config.data);
  }
  if (config.url.indexOf('csapi1') > -1 || config.url.indexOf('csapi') > -1) {
    config.headers['identify-key'] = '9fa5edf8-9256-44b4-800e-a8ca72f26d19';
  }
  config.url = config.url.replace(/\|/g, "%7C"); // 处理url的特殊字符|
  return config;
}, function (error) {
  // do something with request error
  // // console.log('请求拦截器error===>', error) // for debug
  return Promise.reject(error);
});
// respone拦截器
service.interceptors.response.use(function (response) {
  //Toast.clear()
  var data = response.data;
  var request = response.request;
  var url = response.config.url;
  // console.log('222',url,response);
  if (request.status === 200) {
    // console.log('222',data);

    return Promise.resolve(data);
    // if (data.code === 1) {
    //   return Promise.resolve(data)
    // } else {
    //   return Promise.reject(data.msg)
    // }
  } else {
    _Message.error({
      message: '服务器连接异常，请稍后连接！',
      duration: 1000
    });
  }
}, function (error) {
  //Toast.clear()
  var code = 0;
  try {
    code = error.response.data.code;
    // // console.log('异常信息：', error)
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      _Message.error({
        message: '超时',
        duration: 2000
      });
      return Promise.reject(error);
    }
  }
  if (code) {
    if (code === 401) {
      _Message.error({
        message: '登录状态已过期，重新登录！',
        duration: 1500
      });
      clearLoginStatus();
      router.push('/LoginOneClick');
    } else {
      var errorMsg = error.response.data.message;
      if (errorMsg !== undefined) {
        _Message.error({
          message: errorMsg,
          duration: 2000
        });
      }
    }
  } else {
    // Message.error({
    //   message: '接口请求失败',
    //   duration: 2000
    // })
  }
  return Promise.reject(error);
});
export default service;
function stringify(o) {
  var j = "";
  for (var i in o) {
    j = j + "&" + i + "=" + o[i];
  }
  return j.substr(1);
}