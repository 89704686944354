import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * 基础路由
 * @type { *[] }
 */

import Layout from '@/views/A-remitMoney/layout/index';
// 直播
import LiveLayout from '@/views/LiveInfo/layout/index';
export var constantRouterMap = [{
  path: '/test',
  component: function component(resolve) {
    return require(['@/views/test/test'], resolve);
  },
  meta: {
    title: '测试001',
    keepAlive: false
  }
}, {
  path: '/transferpage',
  component: function component(resolve) {
    return require(['@/views/payment/transferPage.vue'], resolve);
  },
  meta: {
    title: '中转页面',
    keepAlive: false
  }
}, {
  path: '/image',
  component: function component(resolve) {
    return require(['@/views/test/image'], resolve);
  },
  meta: {
    title: '测试上传图片',
    keepAlive: false
  }
},
// 直播
{
  path: '/liveIndex',
  name: 'liveIndex',
  meta: {
    title: '房地产行业入驻',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualifications/liveIndex.vue');
  }
},
// 分割线---------------------------------------
{
  path: '/LoginOneClick',
  component: function component(resolve) {
    return require(['@/views/LoginOneClick'], resolve);
  },
  meta: {
    title: '一键登录',
    keepAlive: false
  }
}, {
  path: '/wxLogin',
  component: function component(resolve) {
    return require(['@/views/wxLogin.vue'], resolve);
  },
  meta: {
    title: '微信登录',
    keepAlive: false
  }
}, {
  path: '/UserServiceAgreement',
  component: function component(resolve) {
    return require(['@/views/UserServiceAgreement'], resolve);
  },
  meta: {
    title: '用户服务协议',
    keepAlive: false
  }
}, {
  path: '/NoLoginPage',
  component: function component(resolve) {
    return require(['@/views/home/NoLoginPage'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/Search',
  component: function component(resolve) {
    return require(['@/views/home/Search'], resolve);
  },
  meta: {
    title: '搜索',
    keepAlive: false
  }
}, {
  path: '/Classification',
  component: function component(resolve) {
    return require(['@/views/home/Classification'], resolve);
  },
  meta: {
    title: '分类',
    keepAlive: false
  }
}, {
  path: '/CashBackZone',
  component: function component(resolve) {
    return require(['@/views/home/CashBackZone'], resolve);
  },
  meta: {
    title: '返现专区',
    keepAlive: false
  }
}, {
  path: '/GoodsDetails',
  component: function component(resolve) {
    return require(['@/views/home/Goodsdetails'], resolve);
  },
  meta: {
    title: '商品详情',
    keepAlive: false
  }
}, {
  path: '/ConfirmOrder',
  component: function component(resolve) {
    return require(['@/views/home/ConfirmOrder'], resolve);
  },
  meta: {
    title: '确认订单',
    keepAlive: false
  }
}, {
  path: '/payOrder',
  component: function component(resolve) {
    return require(['@/views/home/payOrder'], resolve);
  },
  meta: {
    title: '支付订单',
    keepAlive: false
  }
}, {
  path: '/LuckyConfirmOrder',
  component: function component(resolve) {
    return require(['@/views/home/LuckyConfirmOrder'], resolve);
  },
  meta: {
    title: '确认订单',
    keepAlive: false
  }
},
// 商家支付
{
  path: '/merchantpay',
  component: function component(resolve) {
    return require(['@/views/MerchantPay/index.vue'], resolve);
  },
  meta: {
    title: '支付',
    keepAlive: false
  }
}, {
  path: '/payment-code',
  component: function component(resolve) {
    return require(['@/views/paymentCode/index.vue'], resolve);
  },
  meta: {
    title: '我的收款码',
    keepAlive: false
  }
}, {
  path: '/collection-account',
  component: function component(resolve) {
    return require(['@/views/paymentCode/CollectionAccount.vue'], resolve);
  },
  meta: {
    title: '我的收款码',
    keepAlive: false
  }
}, {
  path: '/carrycash',
  component: function component(resolve) {
    return require(['@/views/paymentCode/CarryCash.vue'], resolve);
  },
  meta: {
    title: '提现',
    keepAlive: false
  }
}, {
  path: '/withdrawal-records',
  component: function component(resolve) {
    return require(['@/views/paymentCode/WithdrawalRecords.vue'], resolve);
  },
  meta: {
    title: '提现记录',
    keepAlive: false
  }
}, {
  path: '/PayMent',
  component: function component(resolve) {
    return require(['@/views/payment/PayMent'], resolve);
  },
  meta: {
    title: '支付',
    keepAlive: false
  }
}, {
  path: '/HelpPay',
  component: function component(resolve) {
    return require(['@/views/payment/HelpPay'], resolve);
  },
  meta: {
    title: '帮我付',
    keepAlive: false
  }
}, {
  path: '/OrderDetail',
  component: function component(resolve) {
    return require(['@/views/home/OrderDetail'], resolve);
  },
  meta: {
    title: '订单详情-待收货',
    keepAlive: false
  }
}, {
  path: '/groupgrabHomePage',
  component: function component(resolve) {
    return require(['@/views/groupgrab/HomePage'], resolve);
  },
  meta: {
    title: '爆团快抢-首页',
    keepAlive: false
  }
}, {
  path: '/GoodsDetails_PT_JL',
  component: function component(resolve) {
    return require(['@/views/groupgrab/GoodsDetails_PT_JL'], resolve);
  },
  meta: {
    title: '拼团接龙-商品详情',
    keepAlive: false
  }
}, {
  path: '/BTConfirmOrder',
  component: function component(resolve) {
    return require(['@/views/groupgrab/BTConfirmOrder'], resolve);
  },
  meta: {
    title: '爆团快抢-确认订单',
    keepAlive: false
  }
}, {
  path: '/SpellGroup',
  component: function component(resolve) {
    return require(['@/views/payment/SpellGroup'], resolve);
  },
  meta: {
    title: '拼团',
    keepAlive: false
  }
}, {
  path: '/PostComments',
  component: function component(resolve) {
    return require(['@/views/home/comment/PostComments'], resolve);
  },
  meta: {
    title: '点评与投诉',
    keepAlive: false
  }
}, {
  path: '/DingDanList',
  component: function component() {
    return import('@/views/home/comment/DingDanList');
  },
  meta: {
    title: '订单列表',
    keepAlive: false
  }
}, {
  path: '/MyDianPingList',
  component: function component() {
    return import('@/views/home/comment/MyDianPingList');
  },
  meta: {
    title: '我的点评',
    keepAlive: false
  }
}, {
  path: '/MyDianPingDetail',
  component: function component() {
    return import('@/views/home/comment/MyDianPingDetail');
  },
  meta: {
    title: '点评详情',
    keepAlive: false
  }
}, {
  path: '/AddReview',
  component: function component() {
    return import('@/views/home/comment/AddReview');
  },
  meta: {
    title: '追加点评',
    keepAlive: false
  }
}, {
  path: '/PingJiaKeFu',
  component: function component() {
    return import('@/views/home/comment/PingJiaKeFu');
  },
  meta: {
    title: '评价客服',
    keepAlive: false
  }
}, {
  path: '/SubmitSuccessful',
  component: function component() {
    return import('@/views/home/comment/SubmitSuccessful');
  },
  meta: {
    title: '',
    keepAlive: false
  }
}, {
  path: '/RankingList',
  component: function component(resolve) {
    return require(['@/views/home/ranking/RankingList'], resolve);
  },
  meta: {
    title: '信购榜',
    keepAlive: false
  }
}, {
  path: '/contrastHomePage',
  component: function component(resolve) {
    return require(['@/views/contrast/HomePage'], resolve);
  },
  meta: {
    title: '比一比',
    keepAlive: false
  }
}, {
  path: '/myCollection',
  component: function component(resolve) {
    return require(['@/views/contrast/myCollection'], resolve);
  },
  meta: {
    title: '我的收藏',
    keepAlive: false
  }
}, {
  path: '/discountAll',
  component: function component(resolve) {
    return require(['@/views/contrast/discountAll'], resolve);
  },
  meta: {
    title: '全网优惠',
    keepAlive: false
  }
}, {
  path: '/discountDetail',
  component: function component(resolve) {
    return require(['@/views/contrast/discountDetail'], resolve);
  },
  meta: {
    title: '全网优惠',
    keepAlive: false
  }
},
//discountAll
{
  path: '/ProductComparison',
  component: function component(resolve) {
    return require(['@/views/contrast/ProductComparison'], resolve);
  },
  meta: {
    title: '比一比',
    keepAlive: false
  }
}, {
  path: '/AddProduct',
  component: function component(resolve) {
    return require(['@/views/contrast/AddProduct'], resolve);
  },
  meta: {
    title: '比一比',
    keepAlive: false
  }
}, {
  path: '/StartComparison',
  component: function component(resolve) {
    return require(['@/views/contrast/StartComparison'], resolve);
  },
  meta: {
    title: '比一比',
    keepAlive: false
  }
},
//比一比-大汇比
{
  path: '/compareLarge',
  component: function component(resolve) {
    return require(['@/views/contrast/compareLarge'], resolve);
  },
  meta: {
    title: '比一比',
    keepAlive: false
  }
}, {
  path: '/subscribeHomePage',
  component: function component(resolve) {
    return require(['@/views/subscribe/HomePage'], resolve);
  },
  meta: {
    title: '订阅收藏',
    keepAlive: false
  }
}, {
  path: '/PriceNotice',
  component: function component(resolve) {
    return require(['@/views/subscribe/PriceNotice'], resolve);
  },
  meta: {
    title: '订阅-降价通知',
    keepAlive: false
  }
}, {
  path: '/myHomePage',
  component: function component(resolve) {
    return require(['@/views/my/HomePage'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/SetMy',
  component: function component(resolve) {
    return require(['@/views/my/setup/SetMy'], resolve);
  },
  meta: {
    title: '我的-设置',
    keepAlive: false
  }
}, {
  path: '/AddressManagement',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddressManagement'], resolve);
  },
  meta: {
    title: '我的-地址管理',
    keepAlive: false
  }
}, {
  path: '/AddAddress',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddAddress'], resolve);
  },
  meta: {
    title: '我的-新增地址',
    keepAlive: false
  }
}, {
  path: '/AddAddressNew',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddAddressNew'], resolve);
  },
  meta: {
    title: '我的-新增地址',
    keepAlive: false
  }
}, {
  path: '/MessagePush',
  component: function component(resolve) {
    return require(['@/views/my/setup/MessagePush'], resolve);
  },
  meta: {
    title: '我的-消息推送',
    keepAlive: false
  }
}, {
  path: '/FeedBack',
  component: function component(resolve) {
    return require(['@/views/my/setup/FeedBack'], resolve);
  },
  meta: {
    title: '我的-意见反馈',
    keepAlive: false
  }
}, {
  path: '/AccountCancellation',
  component: function component(resolve) {
    return require(['@/views/my/setup/AccountCancellation'], resolve);
  },
  meta: {
    title: '我的-账号注销',
    keepAlive: false
  }
}, {
  path: '/AboutUs',
  component: function component(resolve) {
    return require(['@/views/my/setup/AboutUs'], resolve);
  },
  meta: {
    title: '我的-关于我们',
    keepAlive: false
  }
}, {
  path: '/Income',
  component: function component(resolve) {
    return require(['@/views/my/income/Income'], resolve);
  },
  meta: {
    title: '我的-我的收益',
    keepAlive: false
  }
}, {
  path: '/Withdrawal',
  component: function component(resolve) {
    return require(['@/views/my/income/Withdrawal'], resolve);
  },
  meta: {
    title: '我的-提现',
    keepAlive: false
  }
}, {
  path: '/billingDetails',
  component: function component(resolve) {
    return require(['@/views/my/income/billingDetails'], resolve);
  },
  meta: {
    title: '我的-账单',
    keepAlive: false
  }
}, {
  path: '/detailPage',
  component: function component(resolve) {
    return require(['@/views/my/income/detailPage'], resolve);
  },
  meta: {
    title: '我的-账单-详情',
    keepAlive: false
  }
}, {
  path: '/BinDing',
  component: function component(resolve) {
    return require(['@/views/my/income/BinDing'], resolve);
  },
  meta: {
    title: '我的-绑定',
    keepAlive: false
  }
}, {
  path: '/ChangeBinDing',
  component: function component(resolve) {
    return require(['@/views/my/income/ChangeBinDing'], resolve);
  },
  meta: {
    title: '我的-更改绑定',
    keepAlive: false
  }
}, {
  path: '/WithdrawalRecord',
  component: function component(resolve) {
    return require(['@/views/my/income/WithdrawalRecord'], resolve);
  },
  meta: {
    title: '我的-提现记录',
    keepAlive: false
  }
}, {
  path: '/couponHomePage',
  component: function component(resolve) {
    return require(['@/views/my/coupon/HomePage'], resolve);
  },
  meta: {
    title: '我的-优惠券',
    keepAlive: false
  }
}, {
  path: '/SelectCoupon',
  component: function component(resolve) {
    return require(['@/views/my/coupon/SelectCoupon'], resolve);
  },
  meta: {
    title: '我的-选择优惠券',
    keepAlive: false
  }
}, {
  path: '/SelectDPCoupon',
  component: function component(resolve) {
    return require(['@/views/my/coupon/SelectDPCoupon'], resolve);
  },
  meta: {
    title: '我的-选择优惠券',
    keepAlive: false
  }
}, {
  path: '/AllOrders',
  component: function component(resolve) {
    return require(['@/views/my/order/AllOrders'], resolve);
  },
  meta: {
    title: '我的-确认订单',
    keepAlive: true
  }
}, {
  path: '/StartPage',
  component: function component(resolve) {
    return require(['@/views/StartPage'], resolve);
  },
  meta: {
    title: '启动页',
    keepAlive: false
  }
}, {
  path: '/myOrderDetail',
  component: function component(resolve) {
    return require(['@/views/my/order/DetailPage'], resolve);
  },
  meta: {
    title: '我的-订单详情页',
    keepAlive: false
  }
}, {
  path: '/LoginInvitationCode',
  component: function component(resolve) {
    return require(['@/views/LoginInvitationCode'], resolve);
  },
  meta: {
    title: '输入邀请码',
    keepAlive: false
  }
}, {
  path: '/myPostComments',
  component: function component(resolve) {
    return require(['@/views/my/order/PostComments'], resolve);
  },
  meta: {
    title: '我的-发表点评',
    keepAlive: false
  }
}, {
  path: '/WaitingForPayment',
  component: function component(resolve) {
    return require(['@/views/my/order/WaitingForPayment'], resolve);
  },
  meta: {
    title: '我的-等待付款/已取消',
    keepAlive: false
  }
}, {
  path: '/SharingCode',
  component: function component(resolve) {
    return require(['@/views/MyMembers/SharingCode'], resolve);
  },
  meta: {
    title: '我的成员',
    keepAlive: false
  }
}, {
  path: '/Mymembers',
  component: function component(resolve) {
    return require(['@/views/MyMembers/Mymembers'], resolve);
  },
  meta: {
    title: '我的成员',
    keepAlive: false
  }
}, {
  path: '/returnHome',
  component: function component(resolve) {
    return require(['@/views/refund/returnHome'], resolve);
  },
  meta: {
    title: '选择服务',
    keepAlive: false
  }
}, {
  path: '/returnGoods',
  component: function component(resolve) {
    return require(['@/views/refund/returnGoods'], resolve);
  },
  meta: {
    title: '我要退货退款',
    keepAlive: false
  }
}, {
  path: '/returnMoney',
  component: function component(resolve) {
    return require(['@/views/refund/returnMoney'], resolve);
  },
  meta: {
    title: '我要退款',
    keepAlive: false
  }
}, {
  path: '/AfterSale',
  component: function component(resolve) {
    return require(['@/views/After/AfterSale'], resolve);
  },
  meta: {
    title: '售后/退款',
    keepAlive: false
  }
}, {
  path: '/SaleDetail',
  component: function component(resolve) {
    return require(['@/views/After/SaleDetail'], resolve);
  },
  meta: {
    title: '退款详情',
    keepAlive: false
  }
}, {
  path: '/Seelogistics',
  component: function component(resolve) {
    return require(['@/views/Logistics/Seelogistics'], resolve);
  },
  meta: {
    title: '物流信息',
    keepAlive: false
  }
}, {
  path: '/AllComments',
  component: function component(resolve) {
    return require(['@/views/my/order/AllComments'], resolve);
  },
  meta: {
    title: '我的-全部评价',
    keepAlive: false
  }
}, {
  path: '/CommentsSuccess',
  component: function component(resolve) {
    return require(['@/views/my/order/CommentsSuccess'], resolve);
  },
  meta: {
    title: '我的-评价成功',
    keepAlive: false
  }
}, {
  path: '/OtherPlatformDetails',
  component: function component(resolve) {
    return require(['@/views/home/ranking/OtherPlatformDetails'], resolve);
  },
  meta: {
    title: '信购榜',
    keepAlive: false
  }
}, {
  path: '/ComparisonDetails',
  component: function component(resolve) {
    return require(['@/views/contrast/ComparisonDetails'], resolve);
  },
  meta: {
    title: '比一比',
    keepAlive: false
  }
}, {
  path: '/ProDetails',
  component: function component(resolve) {
    return require(['@/views/home/ProDetails'], resolve);
  },
  meta: {
    title: '商品详情',
    keepAlive: false
  }
}, {
  path: '/mycomments',
  component: function component(resolve) {
    return require(['@/views/home/comment/mycomments'], resolve);
  },
  meta: {
    title: '我的点评',
    keepAlive: false
  }
},
// {

//   path: '/GoodsDetail',
//   component: () => import('@/views/home/GoodsDetail'),
//   meta: {
//     title: '商品详情',
//     keepAlive: false
//   }
// },
{
  path: '/InviteFriends',
  component: function component(resolve) {
    return require(['@/views/my/InviteFriends'], resolve);
  },
  meta: {
    title: '邀请好友',
    keepAlive: false
  }
}, {
  path: '/Myintegral',
  component: function component(resolve) {
    return require(['@/views/MyMembers/Myintegral'], resolve);
  },
  meta: {
    title: '我的积分',
    keepAlive: false
  }
}, {
  path: '/FeedBackList',
  component: function component(resolve) {
    return require(['@/views/my/setup/FeedBackList'], resolve);
  },
  meta: {
    title: '我的-反馈记录',
    keepAlive: false
  }
}, {
  path: '/mycoupon',
  component: function component(resolve) {
    return require(['@/views/my/coupon/mycoupon'], resolve);
  },
  meta: {
    title: '我的优惠券',
    keepAlive: false
  }
}, {
  path: '/NewYears',
  component: function component(resolve) {
    return require(['@/views/Giving/NewYears'], resolve);
  },
  meta: {
    title: '年货节',
    keepAlive: false
  }
}, {
  path: '/GiftCard',
  component: function component(resolve) {
    return require(['@/views/Giving/GiftCard.vue'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/ContentDetails',
  component: function component(resolve) {
    return require(['@/views/Giving/ContentDetails'], resolve);
  },
  meta: {
    title: '',
    keepAlive: false
  }
}, {
  path: '/ContentDetailsNEW',
  component: function component(resolve) {
    return require(['@/views/Giving/ContentDetailsNEW'], resolve);
  },
  meta: {
    title: '',
    keepAlive: false
  }
}, {
  path: '/RecBle',
  component: function component(resolve) {
    return require(['@/views/Giving/RecBle'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/YearPage',
  component: function component(resolve) {
    return require(['@/views/activity/NewYearFestival/YearPage'], resolve);
  },
  meta: {
    title: '让年货与爱一起回家',
    keepAlive: false
  }
}, {
  path: '/PaySuccess',
  component: function component(resolve) {
    return require(['@/views/Giving/PaySuccess'], resolve);
  },
  meta: {
    title: '支付成功',
    keepAlive: false
  }
}, {
  path: '/agreement',
  component: function component(resolve) {
    return require(['@/views/agreement'], resolve);
  },
  meta: {
    title: '用户协议',
    keepAlive: false
  }
}, {
  path: '/newAgreement',
  component: function component(resolve) {
    return require(['@/views/newAgreement'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  path: '/GiftBag',
  component: function component(resolve) {
    return require(['@/views/Giving/GiftBag'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/LeaveBless',
  component: function component(resolve) {
    return require(['@/views/Giving/LeaveBless'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/ChooseCard',
  component: function component(resolve) {
    return require(['@/views/Giving/ChooseCard'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/SelectCard',
  component: function component(resolve) {
    return require(['@/views/Giving/SelectCard'], resolve);
  },
  meta: {
    title: '选择礼品卡',
    keepAlive: false
  }
}, {
  path: '/groundCard',
  component: function component(resolve) {
    return require(['@/views/Giving/groundCard'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/LookMore',
  component: function component(resolve) {
    return require(['@/views/Giving/LookMore'], resolve);
  },
  meta: {
    title: '新年至礼亲朋',
    keepAlive: false
  }
}, {
  path: '/report',
  component: function component(resolve) {
    return require(['@/views/Giving/report'], resolve);
  },
  meta: {
    title: '举报笔记',
    keepAlive: false
  }
}, {
  path: '/LuckDraw',
  component: function component(resolve) {
    return require(['@/views/Giving/LuckDraw'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
},
// 抽奖规则
{
  path: '/LotteryRules',
  component: function component(resolve) {
    return require(['@/components/LotteryRules'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
},
// 中奖记录
{
  path: '/WinningRecord',
  component: function component(resolve) {
    return require(['@/components/WinningRecord'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/Solitaire',
  component: function component(resolve) {
    return require(['@/views/groupgrab/Solitaire'], resolve);
  },
  meta: {
    title: '接龙快抢',
    keepAlive: false
  }
}, {
  path: '/Collage',
  component: function component(resolve) {
    return require(['@/views/groupgrab/Collage'], resolve);
  },
  meta: {
    title: '拼团特卖',
    keepAlive: false
  }
}, {
  path: '/Cashback',
  component: function component(resolve) {
    return require(['@/views/groupgrab/Cashback'], resolve);
  },
  meta: {
    title: '返现专区',
    keepAlive: false
  }
}, {
  path: '/ShopHomePage',
  component: function component(resolve) {
    return require(['@/views/Shop/ShopHomePage'], resolve);
  },
  meta: {
    title: '店铺-宝贝',
    keepAlive: false
  }
}, {
  path: '/ShopDynamic',
  component: function component(resolve) {
    return require(['@/views/Shop/ShopDynamic'], resolve);
  },
  meta: {
    title: '店铺-动态',
    keepAlive: false
  }
}, {
  path: '/ShopActivity',
  component: function component(resolve) {
    return require(['@/views/Shop/ShopActivity'], resolve);
  },
  meta: {
    title: '店铺-活动',
    keepAlive: false
  }
}, {
  path: '/ShopComment',
  component: function component(resolve) {
    return require(['@/views/Shop/ShopComment'], resolve);
  },
  meta: {
    title: '店铺-点评',
    keepAlive: false
  }
}, {
  path: '/ImpressionPage',
  component: function component(resolve) {
    return require(['@/views/Shop/ImpressionPage'], resolve);
  },
  meta: {
    title: '店铺-印象页',
    keepAlive: false
  }
}, {
  path: '/goodsSearch',
  component: function component(resolve) {
    return require(['@/components/Shop/goodsSearch'], resolve);
  },
  meta: {
    title: '店铺-商品搜索',
    keepAlive: false
  }
}, {
  path: '/shopcard',
  component: function component(resolve) {
    return require(['@/views/shopCard/shopcard'], resolve);
  },
  meta: {
    title: '购物车',
    keepAlive: false
  }
}, {
  path: '/searchList',
  component: function component(resolve) {
    return require(['@/components/home/searchList'], resolve);
  },
  meta: {
    title: '搜索列表',
    keepAlive: false
  }
}, {
  path: '/preference',
  component: function component(resolve) {
    return require(['@/views/contrast/preference'], resolve);
  },
  meta: {
    title: '偏好设置',
    keepAlive: false
  }
}, {
  path: '/Morestartcomparison',
  component: function component(resolve) {
    return require(['@/views/contrast/Morestartcomparison'], resolve);
  },
  meta: {
    title: '产品对比',
    keepAlive: false
  }
}, {
  path: '/payGateWay',
  component: function component(resolve) {
    return require(['@/views/payment/payGateWay'], resolve);
  },
  meta: {
    title: '支付宝支付',
    keepAlive: false
  }
}, {
  path: '/exchange',
  component: function component(resolve) {
    return require(['@/views/my/coupon/exchange'], resolve);
  },
  meta: {
    title: '优惠券兑换',
    keepAlive: false
  }
}, {
  path: '/DetailsRankingList',
  component: function component(resolve) {
    return require(['@/views/home/ranking/DetailsRankingList'], resolve);
  },
  meta: {
    title: '信购榜',
    keepAlive: false
  }
}, {
  path: '/mystyleset',
  component: function component(resolve) {
    return require(['@/views/home/ranking/mystyleset'], resolve);
  },
  meta: {
    title: '我的个性设置',
    keepAlive: false
  }
}, {
  path: '/searchcomparison',
  component: function component(resolve) {
    return require(['@/views/contrast/searchcomparison'], resolve);
  },
  meta: {
    title: '比一比商品列表',
    keepAlive: false
  }
},
//ShopSearch
{
  path: '/ShopSearch',
  component: function component(resolve) {
    return require(['@/views/Shop/ShopSearch'], resolve);
  },
  meta: {
    title: '搜索',
    keepAlive: false
  }
}, {
  path: '/parentChild',
  component: function component(resolve) {
    return require(['@/views/Giving/parentChild'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  }
}, {
  path: '/EditPersonInfo',
  component: function component(resolve) {
    return require(['@/views/my/EditPersonInfo'], resolve);
  },
  meta: {
    title: '修改个人信息',
    keepAlive: false
  }
}, {
  path: '/OccupationInfo',
  component: function component(resolve) {
    return require(['@/views/my/OccupationInfo'], resolve);
  },
  meta: {
    title: '职业选择',
    keepAlive: false
  }
}, {
  path: '/MapAddress',
  component: function component(resolve) {
    return require(['@/views/my/MapAddress'], resolve);
  },
  meta: {
    title: '选择地址',
    keepAlive: false
  }
}, {
  path: '/MapAddressNew',
  component: function component(resolve) {
    return require(['@/views/my/MapAddressNew'], resolve);
  },
  meta: {
    title: '选择地址',
    keepAlive: false
  }
}, {
  path: '/expressInfo',
  // component: resolve => require(['@/views/Giving/expressInfo'], resolve),
  component: function component(resolve) {
    return require(['@/views/Giving/expressInfoNew'], resolve);
  },
  meta: {
    title: '快递信息',
    keepAlive: false
  }
}, {
  path: '/EditInfoName',
  component: function component(resolve) {
    return require(['@/views/my/EditInfoName'], resolve);
  },
  meta: {
    title: '编辑昵称',
    keepAlive: false
  }
}, {
  path: '/videoProgress',
  component: function component(resolve) {
    return require(['@/views/Giving/videoProgress'], resolve);
  },
  meta: {
    title: '编辑昵称',
    keepAlive: false
  }
}, {
  path: '/FloatWindow',
  component: function component(resolve) {
    return require(['@/components/FloatWindow'], resolve);
  },
  meta: {
    title: '下载页面',
    keepAlive: false
  }
}, {
  path: '/cs',
  component: function component(resolve) {
    return require(['@/views/Giving/cs'], resolve);
  },
  meta: {
    title: '编辑昵称',
    keepAlive: false
  }
}, /*IM聊天 */
{
  path: '/chat-index',
  name: 'chatIndex',
  meta: {
    title: '聊天',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/IM/chatIndex.vue'], resolve);
  }
}, {
  path: '/allOrder',
  name: 'allOrder',
  meta: {
    title: '发送订单',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/IM/allOrder.vue'], resolve);
  }
},
// 亲子商品详情
{
  path: '/PC_Details',
  name: 'PC_Details',
  meta: {
    title: '商品详情',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/parent_Child/PC_Details.vue'], resolve);
  }
}, {
  path: '/Wrap',
  name: 'Wrap',
  meta: {
    title: '聊天',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/IM/cs/Wrap.vue'], resolve);
  }
},
/////////////////////////////////////////
/**
 * 亲子核销路由
 */
// 核销
// 首页
{
  path: '/home-page',
  name: 'HomePage',
  meta: {
    title: '核销首页',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/home.vue'], resolve);
  }
},
// 登录
{
  path: '/login-writeoff',
  name: 'Login',
  meta: {
    title: '核销登录页',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/login.vue'], resolve);
  }
},
// 找回密码
{
  path: '/findPassword',
  name: 'FindPassword',
  meta: {
    title: '核销找回密码',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/findPassword.vue'], resolve);
  }
},
// 切换景区
{
  path: '/destinations',
  name: 'Destinations',
  meta: {
    title: '核销数据',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/destinations.vue'], resolve);
  }
},
// 核销扫描二维码
{
  path: '/scanCode',
  name: 'ScanCode',
  meta: {
    title: '核销',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/scanCode.vue'], resolve);
  }
},
// 核销券码
{
  path: '/verification',
  name: 'Verification',
  meta: {
    title: '核销',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/verification.vue'], resolve);
  }
},
// 核销确认页
{
  path: '/sureVerification',
  name: 'SureVerification',
  meta: {
    title: '核销确认',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/sure-verification.vue'], resolve);
  }
},
// 核销记录页
{
  path: '/verificationRecord',
  name: 'VerificationRecord',
  meta: {
    title: '核销确认',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/verification-record.vue'], resolve);
  }
},
// 核销详情页
{
  path: '/verificationDetails',
  name: 'VerificationDetails',
  meta: {
    title: '核销详情',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/verification-details.vue'], resolve);
  }
},
// 个人中心
{
  path: '/personal',
  name: 'Personal',
  meta: {
    title: '个人中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/personal.vue'], resolve);
  }
},
// 修改密码
{
  path: '/change-password',
  name: 'ChangePassword',
  meta: {
    title: '修改登陆密码',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/writeoff/pages/changePassword.vue'], resolve);
  }
},
/////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * 汇赚钱路由
 */
// 汇赚钱跳转页
{
  path: '/remit-money',
  redirect: '/remit-money/product',
  name: 'RemitMoney',
  meta: {
    title: '汇赚钱入口',
    keepAlive: false
  },
  component: Layout,
  children: [{
    path: 'home',
    name: 'Home',
    component: function component(resolve) {
      return require(['@/views/A-remitMoney/tabPage/home.vue'], resolve);
    },
    meta: {
      title: '汇金阁',
      keepAlive: false
    }
  }, {
    path: 'product',
    name: 'product',
    component: function component(resolve) {
      return require(['@/views/A-remitMoney/tabPage/product.vue'], resolve);
    },
    meta: {
      title: '汇金阁',
      keepAlive: false
    }
  }, {
    path: 'mine',
    name: 'mine',
    component: function component(resolve) {
      return require(['@/views/A-remitMoney/tabPage/mine.vue'], resolve);
    },
    meta: {
      title: '汇金阁',
      keepAlive: false
    }
  }]
},
// ios、安卓跳转页，查看支持银行
{
  path: '/look-banks',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/lookBanks.vue'], resolve);
  },
  meta: {
    title: '支持银行',
    keepAlive: false
  }
}, {
  path: '/YesterdayEarnings',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/YesterdayEarnings.vue'], resolve);
  },
  meta: {
    title: '昨日收益',
    keepAlive: false
  }
},
// 活期
{
  path: '/current',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/current.vue'], resolve);
  },
  meta: {
    title: '活期',
    keepAlive: false
  }
},
// 持仓详情
{
  path: '/PositionDetail',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/PositionDetail.vue'], resolve);
  },
  meta: {
    title: '持仓详情',
    keepAlive: false
  }
},
// 收益记录
{
  path: '/EarningsHistory',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/EarningsHistory.vue'], resolve);
  },
  meta: {
    title: '收益记录',
    keepAlive: false
  }
},
// 传参code值，获取各类协议说明
{
  path: '/activity-notice',
  name: 'ActivityNotice',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/activity_notice.vue'], resolve);
  },
  meta: {
    title: '协议说明',
    keepAlive: false
  }
},
// 储值产品详情
{
  path: '/product-detail',
  name: 'ProductDetail',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/productDetail.vue'], resolve);
  },
  meta: {
    title: '产品详情',
    keepAlive: false
  }
},
// 电子账户说明
{
  path: '/electronic-account-remark',
  name: 'Electronic',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/Electronic.vue'], resolve);
  },
  meta: {
    title: '电子账户说明',
    keepAlive: false
  }
},
// 上传身份证
{
  path: '/upload-idCard',
  name: 'UploadIdCard',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/uploadIdCard.vue'], resolve);
  },
  meta: {
    title: '上传身份证',
    keepAlive: false
  }
},
// 上传身份证2
{
  path: '/upload-idCard-two',
  name: 'UploadIdCardTwo',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/uploadIdCardTwo.vue'], resolve);
  },
  meta: {
    title: '上传身份证',
    keepAlive: false
  }
},
// 认证成功/失败
{
  path: '/message-real-name',
  name: 'MessageRealName',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/RealName.vue'], resolve);
  },
  meta: {
    title: '实名认证',
    keepAlive: false
  }
},
// 注销成功
{
  path: '/logout-success',
  name: 'LogoutSuccess',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/logoutSuccess.vue'], resolve);
  },
  meta: {
    title: '注销成功',
    keepAlive: false
  }
},
// 绑定银行卡
{
  path: '/binding-bankCard',
  name: 'BindingBankCard',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/bindingBankCard.vue'], resolve);
  },
  meta: {
    title: '上传身份证件',
    keepAlive: false
  }
},
// 开户成功/失败
{
  path: '/message-open-account',
  name: 'MessageOpenAccount',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/openAccount.vue'], resolve);
  },
  meta: {
    title: '开户状态',
    keepAlive: false
  }
},
// 购买详情
{
  path: '/buy-detail',
  name: 'BuyDetail',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/buyDetail.vue'], resolve);
  },
  meta: {
    title: '购买详情',
    keepAlive: false
  }
},
// 购买结果
{
  path: '/buy-result',
  name: 'BuyResult',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/buyResult.vue'], resolve);
  },
  meta: {
    title: '购买结果',
    keepAlive: false
  }
},
// 电子账户总览
{
  path: '/account-overview',
  name: 'AccountOverview',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/accountOverview.vue'], resolve);
  },
  meta: {
    title: '电子账户总览',
    keepAlive: false
  }
},
// 电子账户详情页 银行卡详情
{
  path: '/account-detail',
  name: 'AccountDetail',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/accountDetail.vue'], resolve);
  },
  meta: {
    title: '账户详情',
    keepAlive: false
  }
},
// 交易记录查询
{
  path: '/trading-record',
  name: 'TradingRecord',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/tradingRecord.vue'], resolve);
  },
  meta: {
    title: '交易明细',
    keepAlive: false
  }
},
// 转账充值买入说明
{
  path: '/transfer-remark',
  name: 'TransferRemark',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/transferRemark.vue'], resolve);
  },
  meta: {
    title: '转账充值买入说明',
    keepAlive: false
  }
}, {
  path: '/update-info',
  name: 'UpdateInfo',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/updateInfo.vue'], resolve);
  },
  meta: {
    title: '信息变更',
    keepAlive: false
  }
}, {
  path: '/update-phone',
  name: 'UpdatePhone',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/updatePhone.vue'], resolve);
  },
  meta: {
    title: '修改预留手机号',
    keepAlive: false
  }
}, {
  path: '/updatePhone-result',
  name: 'UpdatePhone',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/updatePhoneResult.vue'], resolve);
  },
  meta: {
    title: '修改预留手机号',
    keepAlive: false
  }
}, {
  path: '/huizhuanqian',
  name: 'Hui-Zhuan-Qian',
  meta: {
    title: '汇金阁',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/huizhuanqian.vue'], resolve);
  }
}, {
  path: '/pay-success',
  name: 'PaySuccess',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/paySuccess.vue'], resolve);
  },
  meta: {
    title: '充值结果',
    keepAlive: false
  }
},
// 产品赎回结果
{
  path: '/ransom-result',
  name: 'RansomResult',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/ransomResult.vue'], resolve);
  },
  meta: {
    title: '赎回结果',
    keepAlive: false
  }
},
// 银行卡注销手机号验证码验证
{
  path: '/verification-phone-message',
  name: 'VerificationMessage',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/verificationMessageCode.vue'], resolve);
  },
  meta: {
    title: '账户注销',
    keepAlive: false
  }
},
// 银行卡账户注销步骤二
{
  path: '/unbind-bank',
  name: 'UnbindBank',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/unbindBank.vue'], resolve);
  },
  meta: {
    title: '账户注销',
    keepAlive: false
  }
},
// 解绑银行卡实名认证成功/失败页
{
  path: '/unbind-bank-real',
  name: 'UnbindBankReal',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/unbindBankReal.vue'], resolve);
  },
  meta: {
    title: '账户注销',
    keepAlive: false
  }
},
// 解绑银行卡实名认证成功/失败页
{
  path: '/unbind-success',
  name: 'UnbindSuccess',
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/unbindSuccess.vue'], resolve);
  },
  meta: {
    title: '账户注销',
    keepAlive: false
  }
},
// 亲子商品详情
{
  path: '/PC_Details',
  name: 'PC_Details',
  meta: {
    title: '商品详情',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/parent_Child/PC_Details.vue'], resolve);
  }
}, {
  path: '/Wrap',
  name: 'Wrap',
  meta: {
    title: '聊天',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/IM/cs/Wrap.vue'], resolve);
  }
},
/**
 *入驻开通收款码四步*
 *  入驻入口首页/settlementPayment
 *  入驻状态首页/paymentActivation
 *    第一步 基础信息审核
 *          --业务类型
 *          --证件信息
 *          --开户信息
 *          --经营信息
 *          --合同预览
 *    第二步 平台服务签约
 *          --企业认证
 *          --分享企业认证
 *          --人脸识别
 *          --企业认证认证成功
 *    第三步 支付通道签约
 *    第四步 支付认证
 *
 */
//入驻入口首页
{
  path: '/settlementPayment',
  name: 'settlementPayment',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/settlementPayment.vue');
  }
},
//入驻状态首页
{
  path: '/paymentActivation',
  name: 'paymentActivation',
  meta: {
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/paymentActivation.vue'], resolve);
  }
},
/**
 * 第一步 基础信息审核
 * basicInformation文件下
 */
// 1)业务类型
{
  path: '/serviceType',
  name: 'serviceType',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/basicInformation/serviceType.vue');
  }
},
// 2)证件信息
{
  path: '/merchantInfomation',
  name: 'merchantInfomation',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/basicInformation/merchantInfomation.vue');
  }
},
// 3)开户信息
{
  path: '/accountInfomation',
  name: 'accountInfomation',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/basicInformation/accountInfomation.vue');
  }
},
// 4)经营信息
{
  path: '/managementInformation',
  name: 'managementInformation',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/basicInformation/managementInformation.vue');
  }
},
// 5)合同预览
{
  path: '/contractpreview',
  name: 'ContractPreview',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/basicInformation/contractPreview.vue');
  }
},
// 5)安卓微信合同下载预览
{
  path: '/previewAndroid',
  name: 'previewAndroid',
  meta: {
    title: '合同下载预览',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/basicInformation/previewAndroid.vue');
  }
},
/**
 * 第二步 平台服务签约 
 * joinEnter文件下
 */
//企业认证
{
  path: '/enterprise',
  name: 'enterprise',
  meta: {
    title: '企业认证',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/authentication/enterprise.vue');
  }
},
//分享企业认证
{
  path: '/shareEnterprise',
  name: 'ShareEnterprise',
  meta: {
    title: '企业认证',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/authentication/shareEnterprise.vue');
  }
},
//人脸识别
{
  path: '/recognition',
  name: 'recognition',
  meta: {
    title: '企业认证',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/authentication/recognition.vue');
  }
},
//企业认证认证成功
{
  path: '/enterpriseSuccessful',
  name: 'EnterpriseSuccessful',
  meta: {
    title: '企业认证',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/authentication/enterpriseSuccessful.vue');
  }
},
/**
 * 第三步 支付通道签约
 */
// 商业入驻审核阶段支付通道
{
  path: '/approvalStage',
  name: 'approvalStage',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/paymentChannel/approvalStage.vue');
  }
},
// 支付通道签约开通支付的页面
{
  path: '/openPayment',
  name: 'openPayment',
  meta: {
    title: '开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/paymentChannel/openPayment.vue');
  }
},
// 支付签约
{
  path: '/payContract',
  name: 'payContract',
  meta: {
    title: '支付签约',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/paymentChannel/payContract.vue');
  }
},
// 认证页面(支付宝和微信)
{
  path: '/payAuthentication',
  name: 'PayAuthentication',
  meta: {
    title: '支付签约',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/paymentChannel/payAuthentication.vue');
  }
},
//公证处入驻
{
  path: '/notarialOffice',
  name: 'NotarialOffice',
  meta: {
    title: '公证处入驻',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/notarialOffice/index.vue'], resolve);
  }
},
/**
 * 入驻类型选择
 *    入驻首页
 *    --商业入驻首页
 *    --物业入驻首页
 */
// 入驻类型选择
{
  path: '/settlePage',
  //入驻首页
  name: 'settlePage',
  meta: {
    title: '入驻类型选择',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/joinEnter/settlePage.vue'], resolve);
  }
},
//商业入驻首页入口
{
  path: '/settleShop',
  name: 'settleShop',
  meta: {
    title: '商业入驻首页',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/joinEnter/settleShop.vue');
  }
},
//物业入驻首页入口
{
  path: '/settleProperty',
  name: 'settleProperty',
  meta: {
    title: '物业入驻首页',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/joinEnter/settleProperty.vue');
  }
},
/**
 * 物业入驻资质提交
 */
// 物业行业资质提交
{
  path: '/qualificationsFirst',
  name: 'qualificationsFirst',
  meta: {
    title: '物业行业入驻',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/qualificationsFirst.vue');
  }
},
// 物业行业
{
  path: '/qualificationsFirst2',
  name: 'qualificationsFirst2',
  meta: {
    title: '物业行业入驻',
    keepAlive: false
  },
  // component: () => import('@/views/settleIn/qualificationsHouse/qualificationsFirst2.vue')
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/qualificationsFirst2.vue');
  }
},
// 物业行业资质提交
{
  path: '/qualificationsHouse',
  name: 'qualificationsHouse',
  meta: {
    title: '物业行业入驻',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/qualificationsSubmit.vue');
  }
},
// 物业行业资质审核页面
{
  path: '/pendingHouse',
  //资质审核页面
  name: 'pendingHouse',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/pendingHouse.vue');
  }
},
// 物业行业直接开通支付
{
  path: '/payHouseDirect',
  //直接开通支付
  name: 'payHouseDirect',
  meta: {
    title: '直接开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/payHouseDirect.vue');
  }
},
// 物业行业直接开通支付审核
{
  path: '/directPay',
  //直接开通支付
  name: 'directPay',
  meta: {
    title: '直接开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/directPay.vue');
  }
},
// 物业行业资质审核
{
  path: '/pendingReviewHouse',
  //资质审核页面
  name: 'pendingReviewHouse',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/pendingReviewHouse.vue');
  }
},
// 物业行业开通支付
{
  path: '/houseCorporate',
  //企业对公资质-开通支付
  name: 'houseCorporate',
  meta: {
    title: '房地产开通支付',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/qualificationsHouse/houseCorporate.vue');
  }
}, {
  path: '/qualificationsSubmit',
  //资质提交页面
  name: 'qualificationsSubmit',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/qualifications/qualificationsSubmit.vue'], resolve);
  }
}, {
  path: '/qualificationsDirect',
  //直接开通支付
  name: 'qualificationsDirect',
  meta: {
    title: '直接开通支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/qualifications/qualificationsDirect.vue'], resolve);
  }
}, {
  path: '/pendingReview',
  //资质审核页面
  name: 'pendingReview',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/qualifications/pendingReview.vue'], resolve);
  }
}, {
  path: '/directReview',
  //直接开通支付审核页面
  name: 'directReview',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/qualifications/directReview.vue'], resolve);
  }
},
// 创建店铺
{
  path: '/shopHome',
  //店铺类型选择
  name: 'shopHome',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/shopHome.vue'], resolve);
  }
}, {
  path: '/onlineType',
  //网店类型选择
  name: 'onlineType',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/onlineType.vue'], resolve);
  }
}, {
  path: '/numberType',
  //是否需要上架选项页面
  name: 'numberType',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/numberType.vue'], resolve);
  }
},
// 品牌官方店
{
  path: '/officialStore',
  //品牌官方店第一步
  name: 'officialStore',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/officialStore.vue'], resolve);
  }
}, {
  path: '/officialStoreSecond',
  //品牌官方店第二步
  name: 'officialStoreSecond',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/officialStoreSecond.vue'], resolve);
  }
}, {
  path: '/officialStoreThird',
  //品牌官方店第三步
  name: 'officialStoreThird',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/officialStoreThird.vue'], resolve);
  }
},
// 品牌旗舰店
{
  path: '/flagStoreFirst',
  //品牌旗舰店第一步
  name: 'flagStoreFirst',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/flagshipStore/flagStoreFirst.vue'], resolve);
  }
}, {
  path: '/flagStoreSecond',
  //品牌旗舰店第二步
  name: 'flagStoreSecond',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/flagshipStore/flagStoreSecond.vue'], resolve);
  }
}, {
  path: '/flagStoreThird',
  //品牌旗舰店第三步
  name: 'flagStoreThird',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/flagshipStore/flagStoreThird.vue'], resolve);
  }
},
// 品牌专卖店
{
  path: '/exclusiveFirst',
  //品牌专卖店第一步
  name: 'exclusiveFirst',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/exclusiveShop/exclusiveFirst.vue'], resolve);
  }
}, {
  path: '/exclusiveSecond',
  //品牌专卖店第二步
  name: 'exclusiveSecond',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/exclusiveShop/exclusiveSecond.vue'], resolve);
  }
}, {
  path: '/exclusiveThird',
  //品牌专卖店第三步
  name: 'exclusiveThird',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/exclusiveShop/exclusiveThird.vue'], resolve);
  }
},
// 品牌专营店
{
  path: '/franchiseFirst',
  //品牌专营店第一步
  name: 'franchiseFirst',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/franchiseStore/franchiseFirst.vue'], resolve);
  }
}, {
  path: '/franchiseSecond',
  //品牌专营店第二步
  name: 'franchiseSecond',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/franchiseStore/franchiseSecond.vue'], resolve);
  }
}, {
  path: '/franchiseThird',
  //品牌专营店第三步
  name: 'franchiseThird',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/franchiseStore/franchiseThird.vue'], resolve);
  }
},
// 数字孪生店
{
  path: '/twins',
  //数字孪生需要上架第一步
  name: 'twins',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component() {
    return import('@/views/settleIn/createShop/twinsStore/twins.vue');
  }
}, {
  path: '/twinsFirst',
  //数字孪生需要上架第一步
  name: 'twinsFirst',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/twinsStore/twinsFirst.vue'], resolve);
  }
}, {
  path: '/twinsSecond',
  //数字孪生需要上架第二步
  name: 'twinsSecond',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/twinsStore/twinsSecond.vue'], resolve);
  }
}, {
  path: '/twinsThird',
  //数字孪生需要上架第三步
  name: 'twinsThird',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/twinsStore/twinsThird.vue'], resolve);
  }
}, {
  path: '/noShelf',
  //不需要上架商品第一步
  name: 'noShelf',
  meta: {
    title: '九不欺开放平台全国招商',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/twinsStore/noShelf.vue'], resolve);
  }
}, {
  path: '/twinsPay',
  //开通支付-数字孪生店-无需上架商
  name: 'twinsPay',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/openPayment/twinsPay.vue'], resolve);
  }
}, {
  path: '/examineSuccess',
  //开通支付审核页面
  name: 'examineSuccess',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/openPayment/examineSuccess.vue'], resolve);
  }
}, {
  path: '/payCorporate',
  //企业对公资质-开通支付
  name: 'payCorporate',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/openPayment/payCorporate.vue'], resolve);
  }
}, {
  path: '/payPersonal',
  //个人资质-开通支付
  name: 'payPersonal',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/openPayment/payPersonal.vue'], resolve);
  }
}, {
  path: '/individual',
  //个体工商户个人资质-开通支付
  name: 'individual',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/openPayment/individual.vue'], resolve);
  }
}, {
  path: '/examine',
  //审核页面
  name: 'examine',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/createShop/examine.vue'], resolve);
  }
},
// 申诉
{
  path: '/appealInfo',
  //申诉成功
  name: 'appealInfo',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/appeal/appealInfo.vue'], resolve);
  }
}, {
  path: '/storeComplaints',
  //门店申诉(点击去申诉跳转到这里)
  name: 'storeComplaints',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/appeal/storeComplaints.vue'], resolve);
  }
},
// 高德地图定位搜索
{
  path: '/gaoDeMap',
  name: 'GaoDeMap',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/appeal/gaoDeMap.vue'], resolve);
  }
}, {
  path: '/pendingAppeal',
  //申诉中
  name: 'pendingAppeal',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/appeal/pendingAppeal.vue'], resolve);
  }
}, {
  path: '/searchProName',
  //输入商品名找标准库(标准库)
  name: 'searchProName',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/searchProduct/searchProName.vue'], resolve);
  }
}, {
  path: '/editAgency',
  //编辑代理公司
  name: 'editAgency',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/searchProduct/editAgency.vue'], resolve);
  }
}, {
  path: '/chooseBrand',
  //选择商标列表
  name: 'chooseBrand',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/searchProduct/chooseBrand.vue'], resolve);
  }
}, {
  path: '/manyAgency',
  //多家代理公司
  name: 'manyAgency',
  meta: {
    title: '申请通联支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/settleIn/searchProduct/manyAgency.vue'], resolve);
  }
}, {
  path: '/editpwd',
  name: 'editpwd',
  meta: {
    title: '忘记密码',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/components/Login/EditPassWord.vue'], resolve);
  }
},
// 中秋节活动页
{
  path: '/MidAutumn',
  name: 'MidAutumn',
  meta: {
    title: '防疫专区',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/activity/MidAutumnActivity/MidAutumn.vue'], resolve);
  }
}, /*****新首页路径*******/
{
  path: '/index',
  name: 'index',
  meta: {
    title: '首页',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/NewHome/index.vue'], resolve);
  }
}, {
  path: '/AppManage',
  name: 'AppManage',
  meta: {
    title: '应用管理',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/NewHome/AppManage.vue'], resolve);
  }
},
//拖拽测试
{
  path: '/testDraggable',
  name: 'testDraggable',
  meta: {
    title: '测试',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/NewHome/testDraggable.vue'], resolve);
  }
}, {
  path: '',
  name: 'home',
  meta: {
    title: '信富生活',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/NewHome/home.vue'], resolve);
  }
}, {
  path: '/home',
  name: 'home',
  meta: {
    title: '信富生活',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/NewHome/home.vue'], resolve);
  }
}, {
  path: '/LiveFloatWindow',
  component: function component(resolve) {
    return require(['@/components/LiveFloatWindow'], resolve);
  },
  meta: {
    title: '直播汇',
    keepAlive: false
  }
}, {
  path: '/baiduPhone-page',
  name: 'BaiduPhonePage',
  meta: {
    title: '实名认证',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/A-remitMoney/components/baiduPhone.vue'], resolve);
  }
}, {
  path: '/NewUserFuLi',
  name: 'NewUserFuLi',
  meta: {
    title: '新客专享福利',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/NewUserFuLi.vue'], resolve);
  }
}, {
  path: '/LifePay',
  name: 'LifePay',
  meta: {
    title: '惠交费',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/LifePay.vue'], resolve);
  }
}, {
  path: '/Boundhouse',
  name: 'Boundhouse',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/Boundhouse.vue'], resolve);
  }
}, {
  path: '/Boundhouse2',
  name: 'Boundhouse2',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/Boundhouse2.vue'], resolve);
  }
}, {
  path: '/Boundhouse3',
  name: 'Boundhouse3',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/Boundhouse3.vue'], resolve);
  }
}, {
  path: '/Boundhouse4',
  name: 'Boundhouse4',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/Boundhouse4.vue'], resolve);
  }
}, {
  path: '/Boundhouse5',
  name: 'Boundhouse5',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/Boundhouse5.vue'], resolve);
  }
}, {
  path: '/AddPay',
  name: 'AddPay',
  meta: {
    title: '惠交费',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/AddPay.vue'], resolve);
  }
}, {
  path: '/huipayment',
  name: 'HuiPayment',
  meta: {
    title: '支付成功',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/HuiPayment.vue'], resolve);
  }
}, {
  path: '/ChooseHouse',
  name: 'ChooseHouse',
  meta: {
    title: '选择房屋信息',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/ChooseHouse.vue'], resolve);
  }
}, {
  path: '/ChooseCity',
  name: 'ChooseCity',
  meta: {
    title: '选择城市',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/ChooseCity.vue'], resolve);
  }
}, {
  path: '/UserDetail',
  name: 'UserDetail',
  meta: {
    title: '账户详情',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/UserDetail.vue'], resolve);
  }
}, {
  path: '/JiaoFeiConfirmOrder',
  name: 'JiaoFeiConfirmOrder',
  meta: {
    title: '确认订单',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/JiaoFeiConfirmOrder.vue'], resolve);
  }
}, {
  path: '/PayHistory',
  name: 'PayHistory',
  meta: {
    title: '缴费纪录',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/PayHistory.vue'], resolve);
  }
}, {
  path: '/unboundHome',
  name: 'unboundHome',
  meta: {
    title: '惠交费',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/unboundHome.vue'], resolve);
  }
}, {
  path: '/LookHistoryPayDetail',
  name: 'LookHistoryPayDetail',
  meta: {
    title: '账单详情',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/HuiPay/LookHistoryPayDetail.vue'], resolve);
  }
}, {
  path: '/Lucky',
  name: 'Lucky',
  meta: {
    title: '支付送好礼',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Lucky/index.vue'], resolve);
  }
},
// 物业转盘
{
  path: '/propertyLucky',
  name: 'PropertyLucky',
  meta: {
    title: '支付送好礼',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Lucky/propertyLucky.vue'], resolve);
  }
}, {
  path: '/LuckyRecord',
  name: 'LuckyRecord',
  meta: {
    title: '中奖纪录',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Lucky/LuckyRecord.vue'], resolve);
  }
}, {
  path: '/newLuckyRecord',
  name: 'NewLuckyRecord',
  meta: {
    title: '中奖纪录',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Lucky/newLuckyRecord.vue'], resolve);
  }
}, {
  path: '/personal-homepage',
  name: 'PersonalHomepage',
  meta: {
    title: '个人主页',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/personalHomepage/index.vue'], resolve);
  }
}, {
  path: '/expert-showcase',
  name: 'ExpertShowcase',
  meta: {
    title: '达人橱窗',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/ExpertShowcase/index'], resolve);
  }
},
// {
//   path: '/liveBroadcast',
//   name: 'liveBroadcast',
//   meta: { title: '直播', keepAlive: true, scrollTop: 0 },
//   component: (resolve) => require(['@/views/LiveInfo/liveBroadcast.vue'], resolve)
// },
{
  path: '/LiveBroadcastRoom',
  name: 'LiveBroadcastRoom',
  meta: {
    title: '直播',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/LiveBroadcastRoom.vue'], resolve);
  }
}, {
  path: '/ClassList',
  component: function component(resolve) {
    return require(['@/views/home/ClassList'], resolve);
  },
  meta: {
    title: '商品分类',
    keepAlive: false
  }
}, {
  path: '/newActivity',
  name: 'newActivity',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/activity/newActivity.vue'], resolve);
  }
}, {
  path: '/activityDetails/:commodityData/:activityTypes',
  name: 'activityDetails',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/activity/activityDetails.vue'], resolve);
  }
}, {
  path: '/NetworkPrice',
  name: 'NetworkPrice',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/contrast/NetworkPrice.vue'], resolve);
  }
}, {
  path: '/ChooseModel',
  name: 'ChooseModel',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/contrast/ChooseModel.vue'], resolve);
  }
}, /**绑定银行卡 */
{
  path: '/Bindbankcard',
  name: 'Bindbankcard',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard/index.vue'], resolve);
  }
}, {
  path: '/bankInfo',
  name: 'bankInfo',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard/bankInfo.vue'], resolve);
  }
}, {
  path: '/Bandbank',
  name: 'Bandbank',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard/Bandbank.vue'], resolve);
  }
}, {
  path: '/BindingSucess',
  name: 'BindingSucess',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard/BindingSucess.vue'], resolve);
  }
}, {
  path: '/AllBank',
  name: 'AllBank',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard/AllBank.vue'], resolve);
  }
}, /**绑定银行卡2*/
{
  path: '/bankInfo2',
  name: 'bankInfo2',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard2/bankInfo.vue'], resolve);
  }
}, {
  path: '/Bandbank2',
  name: 'Bandbank2',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard2/Bandbank.vue'], resolve);
  }
}, {
  path: '/BandbankVISA',
  name: 'BandbankVISA',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard2/BandbankVISA.vue'], resolve);
  }
}, {
  path: '/BindingSucess2',
  name: 'BindingSucess2',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard2/BindingSucess.vue'], resolve);
  }
}, {
  path: '/AllBank2',
  name: 'AllBank2',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Bindbankcard2/AllBank.vue'], resolve);
  }
}, /**实名认证 */
{
  path: '/unRealName',
  name: 'unRealName',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/realName/unRealName.vue'], resolve);
  }
}, {
  path: '/realName',
  name: 'realName',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/realName/realName.vue'], resolve);
  }
}, {
  path: '/faceRecognition',
  name: 'faceRecognition',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/realName/faceRecognition.vue'], resolve);
  }
}, {
  path: '/onFace',
  name: 'onFace',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/realName/onFace.vue'], resolve);
  }
}, {
  path: '/realNameEnter',
  name: 'realNameEnter',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/realName/realNameEnter.vue'], resolve);
  }
}, {
  path: '/ifRealNameEnter',
  name: 'ifRealNameEnter',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/realName/ifRealNameEnter.vue'], resolve);
  }
}, {
  path: '/card',
  name: 'card',
  meta: {
    title: '信用卡开卡',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/creditcard/card.vue'], resolve);
  }
}, {
  path: '/cardApply',
  name: 'cardApply',
  meta: {
    title: '信用卡申请',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/creditcard/cardApply.vue'], resolve);
  }
}, {
  path: '/cardOver',
  name: 'cardOver',
  meta: {
    title: '信用卡申请',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/creditcard/cardOver.vue'], resolve);
  }
}, {
  path: '/cardAgreement',
  name: 'cardAgreement',
  meta: {
    title: '信用卡协议',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/creditcard/cardAgreement.vue'], resolve);
  }
}, {
  path: '/pay-success-page',
  name: 'PaySuccessPage',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/payment/pay-success-page.vue'], resolve);
  }
},
// 滴滴优惠券
{
  path: '/coupon-index',
  name: 'Login',
  meta: {
    title: '滴滴专享福利',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/coupon/pages/login.vue'], resolve);
  }
},
// 海报
{
  path: '/poster-index',
  name: 'Poster',
  meta: {
    title: '滴滴专享福利',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/coupon/pages/poster.vue'], resolve);
  }
},
// 寄件达标奖励
{
  path: '/send-reward',
  component: function component(resolve) {
    return require(['@/sendReward/pages/index.vue'], resolve);
  },
  meta: {
    title: '寄件达标奖励',
    keepAlive: false
  }
},
// 汇速递优惠券
{
  path: '/preferential-index',
  name: 'Login',
  meta: {
    title: '汇速递优惠券大礼包',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/preferential/pages/login.vue'], resolve);
  }
}, {
  path: '/promotion-index',
  name: 'Login',
  meta: {
    title: '汇速递推广',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/preferential/pages/promotion.vue'], resolve);
  }
}, {
  path: '/hsdDownload',
  name: 'Login',
  meta: {
    title: '汇速递下载',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/preferential/pages/hsdDownload.vue'], resolve);
  }
},
// 汇速递海报
{
  path: '/express-poster',
  name: 'ExpressPoster',
  meta: {
    title: '邮政寄件 专享权益',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/ExpressPoster/index.vue'], resolve);
  }
},
//汇速递地址分享
{
  path: '/expressAddress',
  name: 'ExpressAddress',
  meta: {
    title: '汇速递地址',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/express/expressAddress.vue'], resolve);
  }
},
//汇速递操作指引
{
  path: '/Bootstrapping',
  name: 'Bootstrapping',
  meta: {
    title: '操作指引',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/express/Bootstrapping.vue'], resolve);
  }
},
// 海报
{
  path: '/poster-index',
  name: 'Poster',
  meta: {
    title: '滴滴专享福利',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/coupon/pages/poster.vue'], resolve);
  }
},
// 是否加入群聊
{
  path: '/add-group',
  name: 'AddGroup',
  meta: {
    title: '是否加入群聊',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/Giving/AddGroup.vue'], resolve);
  }
},
// 是否加入群聊
{
  path: '/logistics-share',
  name: 'LogisticsShare',
  meta: {
    title: '物流信息',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/components/LogisticsShare/LogisticsShare.vue'], resolve);
  }
}, {
  path: '/supplier',
  name: 'LogisticsShare',
  meta: {
    title: '物流信息',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/supplier.vue'], resolve);
  }
}, {
  path: '/AddressHome',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddressHome'], resolve);
  },
  meta: {
    title: '常用信息',
    keepAlive: false
  }
}, {
  path: '/AddressHomeNew',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddressHomeNew'], resolve);
  },
  meta: {
    title: '常用信息',
    keepAlive: false
  }
}, {
  path: '/AddTourist',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddTourist'], resolve);
  },
  meta: {
    title: '新增出行人信息',
    keepAlive: false
  }
}, {
  path: '/AddReimbursement',
  component: function component(resolve) {
    return require(['@/views/my/setup/AddReimbursement'], resolve);
  },
  meta: {
    title: '新增报销凭证抬头',
    keepAlive: false
  }
}, {
  path: '/LargesinkRatio',
  name: 'LargesinkRatio',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/contrast/LargesinkRatio.vue'], resolve);
  }
}, {
  path: '/discountMoreCoun',
  name: 'discountMoreCoun',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/contrast/discountMoreCoun.vue'], resolve);
  }
}, {
  path: '/subsearch',
  name: 'subsearch',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/subscribe/subsearch.vue'], resolve);
  }
}, {
  path: '/subseachList',
  name: 'subseachList',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/subscribe/subseachList.vue'], resolve);
  }
}, {
  path: '/mymemberorder',
  component: function component(resolve) {
    return require(['@/views/MyMembers/MyOrder'], resolve);
  },
  meta: {
    title: '下单列表',
    keepAlive: false
  }
}, {
  path: '/Healthlecture',
  component: function component(resolve) {
    return require(['@/views/Health/Healthlecture'], resolve);
  },
  meta: {
    title: '健康讲堂',
    keepAlive: false
  }
}, {
  path: '/HealthDetails',
  component: function component(resolve) {
    return require(['@/views/Health/HealthDetails'], resolve);
  },
  meta: {
    title: '健康讲堂',
    keepAlive: false
  }
}, {
  path: '/HealthClassification',
  component: function component(resolve) {
    return require(['@/views/Health/HealthClassification'], resolve);
  },
  meta: {
    title: '健康讲堂',
    keepAlive: false
  }
}, {
  path: '/showWindowGoods',
  name: 'showWindowGoods',
  component: function component(resolve) {
    return require(['@/views/LiveInfo/showWindowGoods'], resolve);
  },
  meta: {
    title: '橱窗商品',
    keepAlive: false
  }
}, {
  path: '/selectShopGoods',
  name: 'selectShopGoods',
  component: function component(resolve) {
    return require(['@/views/LiveInfo/selectShopGoods'], resolve);
  },
  meta: {
    title: '商品选择',
    keepAlive: false
  }
}, {
  path: '/realNameKnow',
  component: function component(resolve) {
    return require(['@/views/realNameKnow/realNameKnow'], resolve);
  },
  meta: {
    title: '汇知道',
    keepAlive: false
    // show: false
  }
}, {
  path: '/flow',
  component: function component(resolve) {
    return require(['@/views/realNameKnow/flow'], resolve);
  },
  meta: {
    title: '汇知道',
    keepAlive: false
    // show: false
  }
}, {
  path: '/authorization',
  component: function component(resolve) {
    return require(['@/views/realNameKnow/authorization'], resolve);
  },
  meta: {
    title: '汇知道',
    keepAlive: false
    // show: false
  }
}, {
  path: '/authorizations',
  component: function component(resolve) {
    return require(['@/views/realNameKnow/authorizations'], resolve);
  },
  meta: {
    title: '汇知道',
    keepAlive: false
    // show: false
  }
}, {
  path: '/ukeCodeShare',
  component: function component(resolve) {
    return require(['@/views/ukeCode/ukeCodeShare'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/ukeCode',
  component: function component(resolve) {
    return require(['@/views/ukeCode/ukeCode'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/ukeNewTabPage',
  component: function component(resolve) {
    return require(['@/views/ukeCode/ukeNewTabPage'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  path: '/ukeActivate',
  component: function component(resolve) {
    return require(['@/views/ukeCode/ukeActivate'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/ukeActivate',
  component: function component(resolve) {
    return require(['@/views/ukeCode/ukeActivate'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/myCodeList',
  component: function component(resolve) {
    return require(['@/views/ukeCode/myCodeList'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/overdrawPage',
  component: function component(resolve) {
    return require(['@/views/ukeCode/overdrawPage'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/overItemPage',
  component: function component(resolve) {
    return require(['@/views/ukeCode/overItemPage'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/overEarningsPage',
  component: function component(resolve) {
    return require(['@/views/ukeCode/overEarningsPage'], resolve);
  },
  meta: {
    // title: '优客码',
    keepAlive: false
    // show: false
  }
}, {
  path: '/eventDisplay',
  component: function component(resolve) {
    return require(['@/views/ukeCode/eventDisplay'], resolve);
  },
  meta: {
    // title: '优客码活动展示',
    keepAlive: false
    // show: false
  }
}, {
  path: '/shopCollectIndex',
  component: function component(resolve) {
    return require(['@/views/shopCollect/shopCollectIndex'], resolve);
  },
  meta: {
    title: '门店汇',
    keepAlive: false
    // show: false
  }
}, {
  path: '/checkAdress',
  component: function component(resolve) {
    return require(['@/views/shopCollect/checkAdress'], resolve);
  },
  meta: {
    // title: '选择收获地址',
    keepAlive: false
    // show: false
  }
}, {
  path: '/cityCheck',
  component: function component(resolve) {
    return require(['@/views/shopCollect/cityCheck'], resolve);
  },
  meta: {
    // title: '选择收获地址',
    keepAlive: false
    // show: false
  }
}, {
  path: '/shopDetail',
  component: function component(resolve) {
    return require(['@/views/shopCollect/shopDetail'], resolve);
  },
  meta: {
    title: '门店汇-店铺详情',
    keepAlive: false
  }
}, {
  path: '/shopMap',
  component: function component(resolve) {
    return require(['@/views/shopCollect/shopMap'], resolve);
  },
  meta: {
    title: '门店汇-商家地址',
    keepAlive: false
  }
}, {
  path: '/shopDisMap',
  component: function component(resolve) {
    return require(['@/views/shopCollect/shopDisMap'], resolve);
  },
  meta: {
    title: '门店汇-配送距离',
    keepAlive: false
  }
}, {
  path: '/shopQuali',
  component: function component(resolve) {
    return require(['@/views/shopCollect/shopQuali'], resolve);
  },
  meta: {
    title: '门店汇-商家资质',
    keepAlive: false
  }
}, {
  path: '/shopQualiAll',
  component: function component(resolve) {
    return require(['@/views/shopCollect/shopQualiAll'], resolve);
  },
  meta: {
    title: '门店汇-商家其余所有资质',
    keepAlive: false
  }
}, {
  path: '/flowPath',
  name: 'flowPath',
  meta: {
    title: '优惠活动权益说明',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/creditcard/flowPath.vue'], resolve);
  }
}, {
  path: '/roomGoodsInfo',
  name: 'roomGoodsInfo',
  meta: {
    title: '橱窗',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/roomGoodsInfo.vue'], resolve);
  }
}, {
  path: '/specifications',
  name: 'specifications',
  meta: {
    title: '橱窗',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/specifications.vue'], resolve);
  }
}, {
  path: '/HuijiCaiShaiH5',
  name: 'HuijiCaiShaiH5',
  component: function component(resolve) {
    return require(['@/views/HuijiCaiShaiH5.vue'], resolve);
  },
  meta: {
    title: '汇集采分享页',
    keepAlive: false
  }
}, {
  path: '/Hxiang_zhi',
  redirect: '/Hxiang_zhi/home',
  name: 'Hui_Xiang_Zhi',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi'], resolve);
  },
  meta: {
    title: '汇相知',
    keepAlive: false
  },
  children: [{
    path: 'home',
    name: 'HuiXiangZhi_Home',
    component: function component(resolve) {
      return require(['@/views/HuiXiangZhi/home'], resolve);
    },
    meta: {
      title: '汇相知-首页',
      keepAlive: false
    }
  }, {
    path: 'userinfo',
    name: 'HuiXiangZhi_Mine',
    component: function component(resolve) {
      return require(['@/views/HuiXiangZhi/userinfo'], resolve);
    },
    meta: {
      title: '汇相知-我的',
      keepAlive: false
    }
  }]
}, {
  path: '/YizhichunShare',
  name: 'YizhichunShare',
  component: function component() {
    return import('@/views/Yizhichun/YizhichunShare.vue');
  },
  meta: {
    title: '企业认证',
    keepAlive: false
  } //易智存分享页
}, {
  path: '/YizhichunShareSuccessful',
  name: 'YizhichunShareSuccessful',
  component: function component() {
    return import('@/views/Yizhichun/YizhichunShareSuccessful.vue');
  },
  meta: {
    title: '认证成功',
    keepAlive: false
  } //易智存分享页
}, {
  path: '/YizhichunShareFallido',
  name: 'YizhichunShareFallido',
  component: function component() {
    return import('@/views/Yizhichun/YizhichunShareFallido.vue');
  },
  meta: {
    title: '认证失败',
    keepAlive: false
  } //易智存分享页
}, /****企业认证H5 (start)****** */
//h5远程验证页面
{
  path: '/EnterpriseCertification',
  name: 'EnterpriseCertification',
  component: function component() {
    return import('@/views/Yizhichun/EnterpriseCertification.vue');
  },
  meta: {
    title: '企业认证',
    keepAlive: false
  }
},
//h5远程验证页面-成功
{
  path: '/EnterpriseCertificationSuccessful',
  name: 'EnterpriseCertificationSuccessful',
  component: function component() {
    return import('@/views/Yizhichun/EnterpriseCertificationSuccessful.vue');
  },
  meta: {
    title: '企业认证',
    keepAlive: false
  }
},
//h5远程验证页面-失败
{
  path: '/EnterpriseCertificationFailure',
  name: 'EnterpriseCertificationFailure',
  component: function component() {
    return import('@/views/Yizhichun/EnterpriseCertificationFailure.vue');
  },
  meta: {
    title: '企业认证',
    keepAlive: false
  }
}, /******企业认证H5**** */
{
  path: '/Hxiang_zhi-login',
  name: 'HuiXiangZhiLogin',
  component: function component() {
    return import('@/views/HuiXiangZhi/component/login');
  },
  meta: {
    title: '欢迎登录汇相知公众号',
    keepAlive: false
  }
}, {
  path: '/personal-info',
  name: 'PersonalInfo',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/personalInfo'], resolve);
  },
  meta: {
    title: '个人信息',
    keepAlive: false
  }
}, {
  path: '/auth-code',
  name: 'AuthCode',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/authCode'], resolve);
  },
  meta: {
    title: '授权码',
    keepAlive: false
  }
}, {
  path: '/my-report',
  name: 'MyReport',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/myReport'], resolve);
  },
  meta: {
    title: '我的报告',
    keepAlive: false
  }
}, {
  path: '/firm-auth',
  name: 'FirmAuth',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/firmAuth'], resolve);
  },
  meta: {
    title: '企业认证',
    keepAlive: false
  }
}, {
  path: '/query-log',
  name: 'QueryLog',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/queryLog'], resolve);
  },
  meta: {
    title: '查询记录',
    keepAlive: false
  }
}, {
  path: '/relation-us',
  name: 'RelationUs',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/relationUs'], resolve);
  },
  meta: {
    title: '联系我们',
    keepAlive: false
  }
}, {
  path: '/about-us',
  name: 'AboutUs',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/aboutUs'], resolve);
  },
  meta: {
    title: '关于我们',
    keepAlive: false
  }
}, {
  path: '/latestVersion',
  name: 'latestVersion',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/latestVersion'], resolve);
  },
  meta: {
    title: '信富生活',
    keepAlive: false
  } //下载新版
}, {
  path: '/hxz-update-phone',
  name: 'HXZUpdatePhone',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/updatePhone'], resolve);
  },
  meta: {
    title: '修改手机号',
    keepAlive: false
  }
}, {
  path: '/hxz-name-auth',
  name: 'HXZNameAuth',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/nameAuth'], resolve);
  },
  meta: {
    title: '实名认证',
    keepAlive: false
  }
}, {
  path: '/hxz-real-success',
  name: 'HXZRealSuccess',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/realSuccess'], resolve);
  },
  meta: {
    title: '认证成功',
    keepAlive: false
  }
}, {
  path: '/hxz-add-firm',
  name: 'HXZAddFirm',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/addFirm'], resolve);
  },
  meta: {
    title: '添加企业',
    keepAlive: false
  }
}, {
  path: '/hxz-scan-qr-code',
  name: 'HXZScanQRCode',
  component: function component(resolve) {
    return require(['@/views/HuiXiangZhi/component/scanQRCode'], resolve);
  },
  meta: {
    title: '扫一扫',
    keepAlive: false
  }
}, {
  path: '/liveRelease',
  name: 'liveRelease',
  meta: {
    title: '直播发布',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/liveRelease.vue'], resolve);
  }
}, {
  path: '/liveActivity',
  name: 'liveActivity',
  meta: {
    title: '活动直播',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/liveActivity.vue'], resolve);
  }
}, {
  path: '/shopInfo',
  name: 'shopInfo',
  meta: {
    title: '选择店铺',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/shop.vue'], resolve);
  }
}, {
  path: '/shopGoods',
  name: 'shopGoods',
  meta: {
    title: '选择商品',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/shopGoods.vue'], resolve);
  }
}, {
  path: '/LiveTicket',
  name: 'LiveTicket',
  meta: {
    title: '直播门票',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/LiveTicket.vue'], resolve);
  }
}, {
  path: '/moreLiveStatus',
  name: 'moreLiveStatus',
  meta: {
    title: '查看更多直播动态',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/moreLiveStatus.vue'], resolve);
  }
}, {
  path: '/activityConfig',
  name: 'activityConfig',
  meta: {
    title: '活动配置',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/activityConfig.vue'], resolve);
  }
}, {
  path: '/advertisingConfig',
  name: 'advertisingConfig',
  meta: {
    title: '广告位配置',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/advertisingConfig.vue'], resolve);
  }
},
/**
 * 直播活动
 */
// 直播活动跳转页
{
  path: '/liveBroadcast',
  redirect: '/liveBroadcast/liveBroadcast',
  name: 'LiveBroadcast',
  meta: {
    title: '大厅',
    keepAlive: false
  },
  component: LiveLayout,
  children: [{
    path: 'liveBroadcast',
    name: 'liveBroadcast',
    component: function component(resolve) {
      return require(['@/views/LiveInfo/liveBroadcast.vue'], resolve);
    },
    meta: {
      title: '大厅',
      keepAlive: false
    }
  }, {
    path: 'activityPreview',
    name: 'activityPreview',
    component: function component(resolve) {
      return require(['@/views/LiveInfo/components/activityPreview.vue'], resolve);
    },
    meta: {
      title: '活动预告',
      keepAlive: false
    }
  }, {
    path: 'myActivityPreview',
    name: 'myActivityPreview',
    component: function component(resolve) {
      return require(['@/views/LiveInfo/components/myActivityPreview.vue'], resolve);
    },
    meta: {
      title: '我的',
      keepAlive: false
    }
  }]
}, {
  path: '/paySuccessActivity',
  name: 'paySuccessActivity',
  meta: {
    title: '活动直播支付',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/components/paySuccess.vue'], resolve);
  }
}, {
  path: '/reservation-live',
  name: 'ReservationLive',
  meta: {
    title: '预约直播',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/ReservationLive.vue'], resolve);
  }
}, {
  path: '/advertisement-notice',
  name: 'AdvertisementNotice',
  meta: {
    title: '直播汇系统通知',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/AdvertisementNotice.vue'], resolve);
  }
}, {
  path: '/open-wps-activity',
  name: 'OpenWpsActivity',
  meta: {
    title: 'WPS文档',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/OpenWpsActivity.vue'], resolve);
  }
}, {
  path: '/recharge-xinfuyuan',
  name: 'RechargeXinFuYuan',
  meta: {
    title: '账户余额',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/RechargeXinFuYuan.vue'], resolve);
  }
}, {
  path: '/advertising-settlement',
  name: 'AdvertisingSettlement',
  meta: {
    title: '结算',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/AdvertisingSettlement.vue'], resolve);
  }
}, {
  path: '/live-income',
  name: 'LiveIncome',
  meta: {
    title: '钱包',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/LiveIncome.vue'], resolve);
  }
}, {
  path: '/payment-completed',
  name: 'PaymentCompleted',
  meta: {
    title: '支付完成',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/LiveInfo/PaymentCompleted.vue'], resolve);
  }
}, {
  path: '/merchant-payment',
  name: 'MerchantPayment',
  meta: {
    title: '支付完成',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/MerchantPay/MerchantPayment.vue'], resolve);
  }
},
//合伙人
{
  //省钱宝典
  path: '/saveMoney',
  component: function component(resolve) {
    return require(['@/views/partner/valuableBook/saveMoney.vue'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  //优选宝典
  path: '/preferenceBook',
  component: function component(resolve) {
    return require(['@/views/partner/valuableBook/preferenceBook.vue'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  //赚钱宝典
  path: '/moneymaking',
  component: function component(resolve) {
    return require(['@/views/partner/valuableBook/moneymaking.vue'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  //申请合伙人
  path: '/applyForPartnership',
  component: function component(resolve) {
    return require(['@/views/partner/applyForPartnership/applyForPartnership.vue'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  //选择资源
  path: '/assetsCheck',
  component: function component(resolve) {
    return require(['@/views/partner/assetsCheck/assetsCheck.vue'], resolve);
  },
  meta: {
    keepAlive: false
  }
}, {
  //视频播放
  path: '/viedoAction',
  component: function component(resolve) {
    return require(['@/views/partner/assetsCheck/viedoAction.vue'], resolve);
  },
  meta: {
    keepAlive: false
  }
},
//合伙人主页
{
  path: '/growthBenefits',
  name: 'GrowthBenefits',
  meta: {
    title: '成长及收益',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/index.vue'], resolve);
  }
},
//客户验证工具
{
  path: '/verificationWidget',
  name: 'VerificationWidget',
  meta: {
    title: '客户验证工具',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/VerificationWidget.vue'], resolve);
  }
},
//推广数据
{
  path: '/promotionData',
  name: 'PromotionData',
  meta: {
    title: '推广数据',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/PromotionData.vue'], resolve);
  }
},
//合伙人列表
{
  path: '/expandMembers',
  name: 'ExpandMembers',
  meta: {
    title: '合伙人列表',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/ExpandMembers.vue'], resolve);
  }
},
//客户邀请流程
{
  path: '/customStep',
  name: 'CustomStep',
  meta: {
    title: '客户邀请流程',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/CustomStep.vue'], resolve);
  }
},
//成员邀请流程
{
  path: '/memberStep',
  name: 'MemberStep',
  meta: {
    title: '成员邀请流程',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/MemberStep.vue'], resolve);
  }
},
//账号注册
{
  path: '/accountRegistration',
  name: 'AccountRegistration',
  meta: {
    title: '账号注册',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/AccountRegistration.vue'], resolve);
  }
},
//合伙人账号注册
{
  path: '/partnerRegistration',
  name: 'PartnerRegistration',
  meta: {
    title: '合伙人账号注册',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/PartnerRegistration.vue'], resolve);
  }
},
//客户回执单
{
  path: '/receipt',
  name: 'Receipt',
  meta: {
    title: '客户回执单',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/Receipt.vue'], resolve);
  }
},
//客户登录
{
  path: '/partnerLogin',
  name: 'PartnerLogin',
  meta: {
    title: '合伙人登录',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/PartnerLogin.vue'], resolve);
  }
},
//申请合伙人
{
  path: '/applyingPartners',
  name: 'ApplyingPartners',
  meta: {
    title: '申请合伙人',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/ApplyingPartners.vue'], resolve);
  }
},
//预备合伙人
{
  path: '/reservePartner',
  name: 'ReservePartner',
  meta: {
    title: '预备合伙人',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/ReservePartner.vue'], resolve);
  }
},
//任务列表
{
  path: '/taskCenter',
  name: 'TaskCenter',
  meta: {
    title: '任务列表',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/TaskCenter.vue'], resolve);
  }
},
//积分详情页
{
  path: '/pointsDetails',
  name: 'PointsDetails',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/growthBenefits/PointsDetails.vue'], resolve);
  }
},
/**
 * 企业账号-管理员角色
 */
//分享企业加入
{
  path: '/shareJoinEnterprise',
  name: 'ShareJoinEnterprise',
  meta: {
    title: '加入企业',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/shareJoinEnterprise/index.vue'], resolve);
  }
},
//合伙人任务中心
{
  path: '/missionCenter',
  name: 'missionCenter',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/index.vue'], resolve);
  }
},
//平台产品推广---主页
{
  path: '/platformProducts',
  name: 'platformProducts',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/platformProducts/platformProducts.vue'], resolve);
  }
},
//平台产品推广---任务认领
{
  path: '/TaskClaim',
  name: 'TaskClaim',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/TaskClaim.vue'], resolve);
  }
},
//招募商家---主页
{
  path: '/Attractbusiness',
  name: 'Attractbusiness',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/Attractbusiness/index.vue'], resolve);
  }
},
//招募商家---任务中心
{
  path: '/AttractbusinessTaskClaim',
  name: 'AttractbusinessTaskClaim',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/Attractbusiness/AttractbusinessTaskClaim.vue'], resolve);
  }
},
//招募商家---任务执行
{
  path: '/AttractbusinessExecute',
  name: 'AttractbusinessExecute',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/Attractbusiness/AttractbusinessExecute.vue'], resolve);
  }
},
//招募商家---资金管理
{
  path: '/AttractbusinessFlowoffunds',
  name: 'AttractbusinessFlowoffunds',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/Attractbusiness/AttractbusinessFlowoffunds.vue'], resolve);
  }
},
//从业者营销---主页
{
  path: '/practitioners',
  name: 'practitioners',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/practitioners/practitioners.vue'], resolve);
  }
},
//从业者营销---任务中心
{
  path: '/PractitionersTaskClaim',
  name: 'PractitionersTaskClaim',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/practitioners/PractitionersTaskClaim.vue'], resolve);
  }
},
//从业者营销---资金管理
{
  path: '/PractitionersFlowoffunds',
  name: 'PractitionersFlowoffunds',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/practitioners/PractitionersFlowoffunds.vue'], resolve);
  }
},
//从业者营销---任务执行
{
  path: '/PractitionersExecute',
  name: 'PractitionersExecute',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/practitioners/PractitionersExecute.vue'], resolve);
  }
},
//从业者营销---转盘
{
  path: '/turnPage',
  name: 'turnPage',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/practitioners/turnPage.vue'], resolve);
  }
},
//招募物业类---主页
{
  path: '/property',
  name: 'property',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/property/index.vue'], resolve);
  }
},
//招募物业类---任务中心
{
  path: '/propertyTaskClaim',
  name: 'propertyTaskClaim',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/property/propertyTaskClaim.vue'], resolve);
  }
},
//招募物业类---任务执行
{
  path: '/propertyExecute',
  name: 'propertyExecute',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/property/propertyExecute.vue'], resolve);
  }
},
//招募物业类---资金管理
{
  path: '/propertyFlowoffunds',
  name: 'propertyFlowoffunds',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/property/propertyFlowoffunds.vue'], resolve);
  }
},
//未签约用户
{
  path: '/unsigned',
  name: 'unsigned',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/unsigned.vue'], resolve);
  }
},
//签约用户
{
  path: '/signacontract',
  name: 'signacontract',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/signacontract.vue'], resolve);
  }
},
//任务执行
{
  path: '/Execute',
  name: 'Execute',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/Execute.vue'], resolve);
  }
},
//资金流水明细
{
  path: '/Flowoffunds',
  name: 'Flowoffunds',
  meta: {
    title: '任务中心',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/Flowoffunds.vue'], resolve);
  }
},
//意见提交分享页
{
  path: '/SubOpinion',
  name: 'SubOpinion',
  // meta: { title: '任务中心', keepAlive: false },
  component: function component(resolve) {
    return require(['@/views/partner/missionCenter/SubOpinion.vue'], resolve);
  }
},
/**
 * 会议报名系统
 */
//会议报名首页
{
  path: '/registrationSystem',
  name: 'registrationSystem',
  meta: {
    title: '报名系统',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/index.vue'], resolve);
  }
},
//创建基础信息
{
  path: '/registrationBasicInfo',
  name: 'registrationBasicInfo',
  meta: {
    title: '创建报名',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/basicInfo.vue'], resolve);
  }
},
//创建参数选择
{
  path: '/selectParams',
  name: 'SelectParams',
  meta: {
    title: '创建报名',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/selectParams.vue'], resolve);
  }
},
//设置与预览
{
  path: '/settingParams',
  name: 'SettingParams',
  meta: {
    title: '创建报名',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/settingParams.vue'], resolve);
  }
},
//历史记录
{
  path: '/meetingHistory',
  name: 'MeetingHistory',
  meta: {
    title: '历史记录',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/meetingHistory.vue'], resolve);
  }
},
// 建议方法
{
  path: '/recommendationMethod',
  name: 'RecommendationMethod',
  meta: {
    title: '建议方法',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/recommendationMethod.vue'], resolve);
  }
},
// 收银台
{
  path: '/PayMents',
  name: 'PayMents',
  meta: {
    title: '收银台',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/PayMents.vue'], resolve);
  }
},
//分享报名
{
  path: '/shareRegistration',
  name: 'ShareRegistration',
  meta: {
    title: '报名系统',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/shareRegistration.vue'], resolve);
  }
},
//海报
{
  path: '/registration-poster',
  name: 'registration-poster',
  meta: {
    title: '海报',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/registrationPoster.vue'], resolve);
  }
},
//报名数据
{
  path: '/registrationHistory',
  name: 'registrationHistory',
  meta: {
    title: '报名数据',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/registrationSystem/registrationHistory.vue'], resolve);
  }
},
// app导航栏中间的导航页
{
  path: "/nav-cantral-tab",
  name: "NavCantralTab",
  component: function component(resolve) {
    return require(['@/views/nav-cantral-tab/NavCantralTab.vue'], resolve);
  }
},
// 使用统一模板的任务中心
{
  path: "/task-type-center",
  name: "TaskTypeCenter",
  component: function component(resolve) {
    return require(['@/views/partner/components/commonTemplate/TaskTypeCenter.vue'], resolve);
  }
},
// 通用的任务认领页面
{
  path: "/common-task-claim",
  name: "CommonTaskClaim",
  component: function component(resolve) {
    return require(['@/views/partner/components/commonTemplate/CommonTaskClaim.vue'], resolve);
  }
},
// 通用的认领明细页面
{
  path: "/common-low-of-funds",
  name: "CommonFlowoffunds",
  component: function component(resolve) {
    return require(['@/views/partner/components/commonTemplate/CommonFlowoffunds.vue'], resolve);
  }
},
// 厂商活动
{
  path: '/activityIndex',
  component: function component(resolve) {
    return require(['@/views/activityPage/activityIndex'], resolve);
  },
  meta: {
    title: '',
    keepAlive: false
  }
},
// 商品详情
{
  path: '/activityDetail',
  name: 'activityDetail',
  meta: {
    title: '',
    keepAlive: false
  },
  component: function component(resolve) {
    return require(['@/views/activityPage/activityDetails.vue'], resolve);
  }
},
// 确认订单
{
  path: '/ConfirmOrders',
  component: function component(resolve) {
    return require(['@/views/activityPage/ConfirmOrder'], resolve);
  },
  meta: {
    title: '确认订单',
    keepAlive: false
  }
},
// 选择优惠券
{
  path: '/couponChoose',
  component: function component(resolve) {
    return require(['@/views/activityPage/couponChoose'], resolve);
  },
  meta: {
    title: '选择优惠券',
    keepAlive: false
  }
},
// 优惠券兑换
{
  path: '/couponExchange',
  component: function component(resolve) {
    return require(['@/views/activityPage/couponExchange'], resolve);
  },
  meta: {
    title: '兑换优惠券',
    keepAlive: false
  }
},
// 业主福利
{
  path: '/ownerBenefits',
  component: function component(resolve) {
    return require(['@/views/activityPage/ownerBenefits'], resolve);
  },
  meta: {
    title: '业主福利',
    keepAlive: false
  }
},
// 福利详情
{
  path: '/welfareDetails',
  component: function component(resolve) {
    return require(['@/views/activityPage/welfareDetails'], resolve);
  },
  meta: {
    title: '福利详情',
    keepAlive: false
  }
}, {
  path: '/ExhibitionHui',
  component: function component(resolve) {
    return require(['@/views/ExhibitionHui/index'], resolve);
  },
  meta: {
    title: '展会活动',
    keepAlive: false
  }
}];