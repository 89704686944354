import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import TabBar from '../layout/TabBar.vue';
import { setTabStatus } from '@/utils/cookies';
import { TokenApi } from '@/config';
import request from '@/utils/request';
import { setUserInfo, getLoginStatus } from '@/utils/cookies';
import utils from 'liudonghan';
export default {
  name: 'AppLayout',
  data: function data() {
    return {
      tabbars: [{
        title: '产品',
        to: {
          name: 'product'
        },
        iconShow: require('@/assets/img/home/chanpin.png'),
        iconHidden: require('@/assets/img/home/chanpin(wei).png')
      }, {
        title: '我的',
        to: {
          name: 'mine'
        },
        iconShow: require('@/assets/img/home/sy-wode.png'),
        iconHidden: require('@/assets/img/home/sy-wode(wei).png')
      }]
    };
  },
  components: {
    TabBar: TabBar
  },
  watch: {
    $route: function $route(value) {
      var path = window.location.href;
      if (path.indexOf('/remit-money/product') > -1) {
        // this.$bus.$emit('changeTabStatus', 0);
        utils.EventBusManagerUtils.post('changeTabStatus', 0);
        this.$refs.tabbar.active = 0;
        setTabStatus(0);
      }
      if (path.indexOf('/remit-money/mine') > -1) {
        // this.$bus.$emit('changeTabStatus', 1);
        utils.EventBusManagerUtils.post('changeTabStatus', 1);
        this.$refs.tabbar.active = 1;
        setTabStatus(1);
      }
    }
  },
  methods: {
    handleChange: function handleChange(v) {},
    // 获取用户信息
    getUserInfo: function getUserInfo() {
      var _this = this;
      this.loadingStatus = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      var data = {
        token: getLoginStatus()
      };
      if (!data.token) {
        return;
      }
      request({
        url: TokenApi + 'F_UserInfo/GetUserInfoByToken',
        method: 'post',
        data: data
      }).then(function (res) {
        if (res.code == 0) {
          localStorage.setItem('user_info', JSON.stringify(res.data));
          setUserInfo(res.data);
        } else {
          _this.$toast(res.msg);
        }
      }).catch(function (err) {
        _this.$toast(err.msg);
      }).finally(function (_) {
        _this.loadingStatus.clear();
      });
    }
  },
  mounted: function mounted() {},
  created: function created() {
    this.getUserInfo();
  }
};