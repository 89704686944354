var _defineProperty = require("E:/work/h5/node_modules/@babel/runtime/helpers/defineProperty.js").default;
var _module$exports;
var buildEnv = process.env.NODE_ENV == 'development'; // 没有 buildenv 则默认生产环境
// 本地环境配置
module.exports = (_module$exports = {
  title: '信富生活',
  baseUrl: 'http://localhost:9018',
  // 项目地址
  // 测试
  baseApi: 'https://csapi1.xinfushenghuo.cn/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // baseApi: 'http://csapi2.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/' SPU环境
  Logistics: 'https://api1.tuansudi.com/',
  //  李凯物流测试（汇速递物流）本地api请求地址,注意：如果你使用了代理，请设置成'/' 物流相关
  orderApi: 'https://orders1.xinfushenghuo.cn/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // orderApi: 'https://orders2.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/' SPU环境
  storeApi: 'https://tapi.9buqi.cn/',
  // 测试店铺相关
  //storeApi: 'https://monitor.9buqi.cn/v1/', // 测试店铺相关
  liveApi: 'https://im.xiaoyutab.cn/',
  //H5直播测试勿删
  ScoreTopApi: 'http://114.113.144.175:9090/',
  // ScoreTopApi: "http://114.113.144.175:9080/", // 测试信购排名    SPU
  Hjicai: 'https://addresstest.xinfushenghuo.cn/addr/',
  //汇集采
  HjCPay: 'https://addresstest.xinfushenghuo.cn/auction/',
  // 汇集采支付
  SignApi: ' https://hzd.xinfushenghuo.cn/hzdApi/authorize/',
  //汇知道签字
  h5Api: 'https://testh5.xinfushenghuo.cn/#/',
  //汇知道签字
  appnameApi: 'https://addresstest.xinfushenghuo.cn/cpd/',
  //全网点评 -- 查所有平台
  hxzApi: 'https://zhenqg.com:14165/',
  // 汇相知
  expertApi: 'https://addresstest.xinfushenghuo.cn/cpd/',
  //达人商店列表
  // 测试
  // baseApi: 'https://csapi1.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // Logistics: 'https://api1.tuansudi.com/', //  李凯物流测试（汇速递物流）本地api请求地址,注意：如果你使用了代理，请设置成'/' 物流相关
  // orderApi: 'https://orders1.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // storeApi: 'https://tapi.9buqi.cn/', // 测试店铺相关
  tapiApi: 'https://tapi.9buqi.cn/'
}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_module$exports, "ScoreTopApi", 'http://114.113.144.175:9090/'), "jinqun", 'http://116.90.87.61:8015/'), "TokenApi", 'https://loginf2.lawxp.com/'), "userApi", '/'), "payApi", 'https://payapi.lawxp.com/'), "APPID", 'xxx'), "APPSECRET", 'xxx'), "$cdn", 'https://www.sunniejs.cn/static'), "IMApi", 'https://im.xiaoyutab.cn/'), "settleinApi", 'https://api1.xinfushenghuo.cn/'), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_module$exports, "ActivityAPI", 'https://tapi.9buqi.cn/'), "AmbaAPI", 'https://ambapi.lawxp.com'), "jiaofeiApi", 'https://wyapi.lawxp.com/'), "payNewApi", 'https://addresstest.xinfushenghuo.cn/auction/'), "message", 'https://loginf.lawxp.com/'), "imBaseApi", 'https://im.xiaoyutab.cn'));