import "core-js/modules/es.object.to-string.js";
import { partnerDetail, partnerNumber } from '@/api/partner/index.js';
var state = {
  partnerInfo: {},
  //当前合伙人信息
  partnerNumber: {} //合伙人数据
};
var mutations = {
  GET_PARTNER_INFO: function GET_PARTNER_INFO(state, data) {
    state.partnerInfo = data;
  },
  GET_PARTNER_NUMBER: function GET_PARTNER_NUMBER(state, data) {
    state.partnerNumber = data;
  }
};
var actions = {
  // 当前合伙人信息
  getPartnerInfo: function getPartnerInfo(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      partnerDetail().then(function (res) {
        commit('GET_PARTNER_INFO', res.data);
        resolve(res.data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 合伙人入驻数量
  getPartnerNumber: function getPartnerNumber(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      partnerNumber().then(function (res) {
        commit('GET_PARTNER_NUMBER', res.data);
        resolve(res.data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};